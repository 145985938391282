@import './mixins';

$picker-partial-width: $picker-partial-cell-width * 7 + $space-s * 2 + 4;
$disabled-color: rgba(0, 0, 0, 0.25);
$picker-partial-cell-height: 24px;
$picker-basic-cell-hover-color: var(--grey-background1-color);
$picker-basic-cell-active-with-range-color: var(--primary-color-40);
$picker-date-hover-range-border-color: var(--primary-color-40);
$picker-date-hover-range-color: var(--primary-background3-color);
$picker-partial-without-time-cell-height: 64px;
$picker-time-partial-column-height: 224px;
$picker-time-partial-column-width: 56px;
$picker-time-partial-cell-height: 28px;

@mixin picker-cell-inner() {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: $picker-partial-cell-height;
  height: $picker-partial-cell-height;
  line-height: $picker-partial-cell-height;
  border-radius: $picker-border-radius;

  &:before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 1;
    height: $picker-partial-cell-height;
    transform: translateY(-50%);
    content: '';
  }

  &:hover:not(&-in-view),
  &:hover:not(&-selected):not(&-range-start):not(&-range-end):not(&-range-hover-start):not(&-range-hover-end) {
    background: $picker-basic-cell-hover-color;
  }

  &-in-view.picker-cell-today {
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      border: var(--picker-border-active);
      border-radius: var(--border-radius-round);
      content: '';
    }
  }

  &-in-view.picker-cell-in-range {
    position: relative;

    &:before {
      background: $picker-basic-cell-active-with-range-color;
    }
  }

  &-in-view.picker-cell-selected,
  &-in-view.picker-cell-range-start,
  &-in-view.picker-cell-range-end {
    background: var(--picker-cell-background-color-active);
    color: var(--picker-cell-foreground-color-active);
  }

  &-in-view.picker-cell-range-start:not(&-range-start-single),
  &-in-view.picker-cell-range-end:not(&-range-end-single) {
    &:before {
      background: $picker-basic-cell-active-with-range-color;
    }
  }

  &-in-view.picker-cell-range-start:before {
    left: 50%;
  }

  &-in-view.picker-cell-range-end:before {
    right: 50%;
  }

  &-in-view.picker-cell-range-hover-start:not(&-in-range):not(&-range-start):not(&-range-end),
  &-in-view.picker-cell-range-hover-end:not(&-in-range):not(&-range-start):not(&-range-end),
  &-in-view.picker-cell-range-hover-start.picker-cell-range-start-single,
  &-in-view.picker-cell-range-hover-start.picker-cell-range-start.picker-cell-range-end.picker-cell-range-end-near-hover,
  &-in-view.picker-cell-range-hover-end.picker-cell-range-start.picker-cell-range-end.picker-cell-range-start-near-hover,
  &-in-view.picker-cell-range-hover-end.picker-cell-range-end-single,
  &-in-view.picker-cell-range-hover:not(&-in-range) {
    &:after {
      position: absolute;
      top: 50%;
      z-index: 0;
      height: 24px;
      border-top: var(--picker-border-width) dashed
        $picker-date-hover-range-border-color;
      border-bottom: var(--picker-border-width) dashed
        $picker-date-hover-range-border-color;
      transform: translateY(-50%);
      content: '';
    }
  }

  &-range-hover-start:after,
  &-range-hover-end:after,
  &-range-hover:after {
    right: 0;
    left: 2px;
  }

  &-in-view.picker-cell-in-range.picker-cell-range-hover:before,
  &-in-view.picker-cell-range-start.picker-cell-range-hover:before,
  &-in-view.picker-cell-range-end.picker-cell-range-hover:before,
  &-in-view.picker-cell-range-start:not(&-range-start-single).picker-cell-range-hover-start:before,
  &-in-view.picker-cell-range-end:not(&-range-end-single).picker-cell-range-hover-end:before,
  .picker-partial
    > :not(.picker-date-partial)
    &-in-view.picker-cell-in-range.picker-cell-range-hover-start:before,
  .picker-partial
    > :not(.picker-date-partial)
    &-in-view.picker-cell-in-range.picker-cell-range-hover-end:before {
    background: $picker-date-hover-range-color;
  }

  &-in-view.picker-cell-range-start:not(&-range-start-single):not(&-range-end) {
    border-radius: $picker-border-radius 0 0 $picker-border-radius;
  }

  &-in-view.picker-cell-range-end:not(&-range-end-single):not(&-range-start) {
    border-radius: 0 $picker-border-radius $picker-border-radius 0;
  }

  .picker-date-partial
    &-in-view.picker-cell-in-range.picker-cell-range-hover-start,
  .picker-date-partial
    &-in-view.picker-cell-in-range.picker-cell-range-hover-end {
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: $picker-date-hover-range-color;
      content: '';
    }
  }

  .picker-date-partial
    &-in-view.picker-cell-in-range.picker-cell-range-hover-start
    &:after {
    right: -5px - var(--picker-border-width);
    left: 0;
  }

  .picker-date-partial
    &-in-view.picker-cell-in-range.picker-cell-range-hover-end
    &:after {
    right: 0;
    left: -5px - var(--picker-border-width);
  }

  &-range-hover.picker-cell-range-start:after {
    right: 50%;
  }

  &-range-hover.picker-cell-range-end:after {
    left: 50%;
  }

  tr > &-in-view.picker-cell-range-hover:first-child:after,
  tr > &-in-view.picker-cell-range-hover-end:first-child:after,
  &-in-view.picker-cell-start.picker-cell-range-hover-edge-start.picker-cell-range-hover-edge-start-near-range:after,
  &-in-view.picker-cell-range-hover-edge-start:not(&-range-hover-edge-start-near-range):after,
  &-in-view.picker-cell-range-hover-start:after {
    left: 6px;
    border-left: var(--picker-border-width) dashed
      $picker-date-hover-range-border-color;
    border-top-left-radius: $picker-border-radius;
    border-bottom-left-radius: $picker-border-radius;
  }

  tr > &-in-view.picker-cell-range-hover:last-child:after,
  tr > &-in-view.picker-cell-range-hover-start:last-child:after,
  &-in-view.picker-cell-end.picker-cell-range-hover-edge-end.picker-cell-range-hover-edge-end-near-range:after,
  &-in-view.picker-cell-range-hover-edge-end:not(&-range-hover-edge-end-near-range):after,
  &-in-view.picker-cell-range-hover-end:after {
    right: 6px;
    border-right: var(--picker-border-width) dashed
      $picker-date-hover-range-border-color;
    border-top-right-radius: $picker-border-radius;
    border-bottom-right-radius: $picker-border-radius;
  }

  &-disabled {
    @include disabled();
    pointer-events: none;
    background: transparent;
  }

  &-disabled.picker-cell-today &:before {
    border-color: $disabled-color;
  }
}

.picker {
  &-partial {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    background: var(--picker-input-background-color);
    border: var(--picker-border);
    border-radius: $picker-border-radius;
    outline: none;

    &-focused {
      border-color: var(--picker-border-color-active);
    }
  }

  &-decade-partial,
  &-year-partial,
  &-quarter-partial,
  &-month-partial,
  &-week-partial,
  &-date-partial,
  &-time-partial {
    display: flex;
    flex-direction: column;
    width: $picker-partial-width;
  }

  &-header {
    display: flex;
    padding: 0 $space-xs;
    color: var(--picker-header-cell-foreground-color);
    border-bottom: var(--picker-border);

    > * {
      flex: none;
    }

    &-view {
      flex: auto;
      font-weight: $text-font-weight-semibold;
      line-height: $picker-text-height;
    }
  }

  &-content {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th,
    td {
      position: relative;
      min-width: 24px;
      font-weight: $text-font-weight-regular;
    }

    th {
      height: 30px;
      color: var(--picker-header-cell-foreground-color);
      line-height: 30px;
    }
  }

  &-cell {
    padding: 3px 0;
    color: $disabled-color;
    cursor: pointer;

    &-in-view {
      color: var(--picker-cell-foreground-color);
    }

    @include picker-cell-inner();
  }

  &-decade-partial,
  &-year-partial,
  &-quarter-partial,
  &-month-partial {
    .picker-content {
      height: $picker-partial-without-time-cell-height * 4;
    }

    .picker-cell-inner {
      padding: 0 $space-xs;
    }
  }

  &-quarter-partial {
    .picker-content {
      height: 56px;
    }
  }

  &-footer {
    width: min-content;
    min-width: 100%;
    line-height: $picker-text-height - 2 * $picker-border-width;
    text-align: center;
    border-bottom: var(--picker-border-width) var(--picker-border-style)
      transparent;

    .picker-partial {
      border-top: var(--picker-border);
    }

    &-extra {
      padding: 0 $space-s;
      line-height: $picker-text-height - 2 * $picker-border-width;
      text-align: left;

      &:not(:last-child) {
        border-bottom: var(--picker-border);
      }
    }
  }

  &-now {
    text-align: left;
  }

  &-today-btn {
    color: var(--primary-color);

    &:hover {
      color: var(--primary-secondary-color);
    }

    &:active {
      color: var(--primary-color);
    }

    &-disabled {
      color: $disabled-color;
      cursor: not-allowed;
    }
  }

  &-decade-partial {
    .picker-cell-inner {
      padding: 0 ($space-xs / 2);
    }

    .picker-cell:before {
      display: none;
    }
  }

  &-year-partial,
  &-quarter-partial,
  &-month-partial {
    .picker-body {
      padding: 0 $space-xs;
    }

    .picker-cell-inner {
      width: $picker-cell-year-month-width-m;
    }
  }

  &-week-partial {
    .picker-body {
      padding: $space-xs $space-s;
    }

    // Clear cell style
    .picker-cell {
      &:hover .picker-cell-inner,
      &-selected .picker-cell-inner,
      .picker-cell-inner {
        background: transparent !important;
      }
    }

    &-row {
      &:hover td {
        background: $picker-basic-cell-hover-color;
      }

      &-selected td,
      &-selected:hover td {
        background: var(--picker-cell-background-color-active);

        &.picker-cell-week {
          color: var(--picker-cell-foreground-color-active);
        }

        &.picker-cell-today .picker-cell-inner:before {
          border-color: var(--text-inverse-color);
        }

        .picker-cell-inner {
          color: var(--text-inverse-color);
        }
      }
    }
  }

  &-date-partial {
    .picker-body {
      padding: $space-xs $space-s;
    }

    .picker-content {
      width: $picker-partial-cell-width * 7;

      th {
        width: $picker-partial-cell-width;
      }
    }
  }

  &-datetime-partial {
    display: flex;

    .picker-time-partial {
      border-left: var(--picker-border);
    }

    &-active {
      .picker-date-partial,
      .picker-time-partial {
        opacity: 0.3;

        &-active {
          opacity: 1;
        }
      }
    }
  }

  &-time-partial {
    width: auto;
    min-width: auto;

    .picker-content {
      display: flex;
      flex: auto;
      height: $picker-time-partial-column-height;
    }

    &-column {
      flex: 1 0 auto;
      width: $picker-time-partial-column-width;
      margin: 0;
      padding: 0;
      overflow-y: hidden;
      text-align: left;
      list-style: none;

      &:after {
        display: block;
        height: $picker-time-partial-column-height -
          $picker-time-partial-cell-height;
        content: '';
        .picker-datetime-partial & {
          height: $picker-time-partial-column-height -
            $picker-time-partial-cell-height + 2 * $picker-border-width;
        }
      }

      &:not(:first-child) {
        border-left: var(--picker-border);
      }

      &-active {
        background: var(--primary-color);
      }

      &:hover {
        overflow-y: auto;
      }

      > li {
        margin: 0;
        padding: 0;

        &.picker-time-partial-cell {
          .picker-time-partial-cell-inner {
            display: block;
            width: 100%;
            height: $picker-time-partial-cell-height;
            margin: 0;
            padding: 0 0 0 (($picker-time-partial-column-width - 28px) / 2);
            color: var(--picker-cell-foreground-color);
            line-height: $picker-time-partial-cell-height;
            border-radius: 0;
            cursor: pointer;

            &:hover {
              background: $picker-basic-cell-hover-color;
            }
          }

          &-selected {
            .picker-time-partial-cell-inner {
              background: var(--picker-cell-foreground-color-active);
              border: 1px solid var(--picker-cell-border-color-active);
            }
          }

          &-disabled {
            .picker-time-partial-cell-inner {
              color: $disabled-color;
              background: transparent;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
