@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.snackbar {
  align-items: start;
  box-shadow: $shadow-object-l;
  border: none;
  font-family: var(--font-stack-full);
  min-width: 21vw;
  max-width: min(42vw, 640px);
  animation: slideUpIn $motion-duration-extra-fast $motion-easing-easeinout 0s
    forwards;
  margin-bottom: $space-s;
  background-color: var(--white-color);

  .content-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $space-xs;

    .content {
      color: inherit;
      display: flex;
      min-width: 100%;
      padding-top: $space-xs;
    }

    .action-button {
      align-self: self-start;
      margin-left: -10px;
    }
  }

  @media (min-width: $medium-screen-size) {
    .content-wrapper {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: $space-m;

      .action-button {
        margin-left: unset;
      }

      .content {
        flex: 1;
        margin-top: unset;
        min-width: unset;
      }
    }
  }

  @media (max-width: $small-screen-size) {
    max-width: 90vw;
  }

  @media (min-width: $small-screen-size) and (max-width: $medium-screen-size) {
    max-width: min(80vw, 640px);
  }
}

.snackbar-container {
  font-family: var(--font-stack-full);
  position: fixed;
  width: fit-content;
  z-index: $z-index-600;

  &.top-center,
  &.bottom-center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.top-center,
  &.top-left,
  &.top-right {
    top: $space-m;
  }

  &.bottom-center,
  &.bottom-left,
  &.bottom-right {
    bottom: $space-m;
  }

  &.top-left,
  &.bottom-left {
    left: $space-m;
  }

  &.top-right,
  &.bottom-right {
    right: $space-m;
  }
}

@keyframes slideUpIn {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 0% 0%;
  }
}
