@mixin drop-indicator() {
  .tree-drop-indicator {
    position: absolute;
    z-index: 1;
    height: 2px;
    background-color: var(--primary-color);
    border-radius: 1px;
    pointer-events: none;

    &:after {
      position: absolute;
      top: -3px;
      left: -6px;
      width: 8px;
      height: 8px;
      background-color: transparent;
      border: 2px solid var(--primary-color);
      border-radius: 50%;
      content: '';
    }
  }
}
