.tree.tree-directory {
  // ================== TreeNode ==================
  .tree-treenode {
    position: relative;

    // Hover color
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 4px;
      left: 0;
      transition: background-color 0.3s;
      content: '';
      pointer-events: none;
    }

    &:hover {
      &:before {
        background: $tree-node-hover-bg;
      }
    }

    // Elements
    > * {
      z-index: 1;
    }

    // >>> Switcher
    .tree-switcher {
      transition: color 0.3s;
    }

    // >>> Title
    .tree-node-content-wrapper {
      border-radius: 0;
      user-select: none;

      &:hover {
        background: transparent;
      }

      &.tree-node-selected {
        color: $tree-directory-selected-color;
        background: transparent;
      }
    }

    // ============= Selected =============
    &-selected {
      &:hover:before,
      &:before {
        background: $tree-directory-selected-bg;
      }

      // >>> Switcher
      .tree-switcher {
        color: $tree-directory-selected-color;
      }

      // >>> Title
      .tree-node-content-wrapper {
        color: $tree-directory-selected-color;
        background: transparent;
      }
    }
  }
}
