@import './mixins';

.row {
  &-rtl {
    direction: rtl;
  }
}

.col {
  &-rtl {
    direction: rtl;
  }
}
