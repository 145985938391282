@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.pagination {
  align-items: center;
  display: flex;
  font-family: var(--font-stack-full);
  padding: $space-xxs 0;

  span,
  .pagination-button {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }

  button.pagination-button {
    &:hover {
      background: var(--pager-button-hover-background-color);
    }

    &:active,
    &.active {
      color: var(--pager-button-active-foreground-color);
      background: var(--pager-button-active-background-color);
      outline: 1px solid var(--pager-button-active-outline-color);

      svg {
        color: var(--pager-button-active-foreground-color);
      }
    }
  }

  .pagination-next {
    margin-left: $space-xxs;
  }

  .pagination-previous {
    margin-right: $space-xxs;
  }

  .sizes {
    display: none;
    margin: 0 $space-xs;
  }

  .jump {
    box-sizing: border-box;
    display: none;
    font-size: $text-font-size-2;
    height: 28px;
    line-height: $text-line-height-4;
    margin: 0 0 0 $space-xs;
    vertical-align: top;

    .editor {
      box-sizing: border-box;
      height: 28px;
      min-width: unset;
      margin: 0 0 0 $space-xs;
      padding-left: $space-xs;
      width: 72px;
    }
  }

  .total {
    box-sizing: border-box;
    display: none;
    font-size: $text-font-size-2;
    height: 28px;
    line-height: $text-line-height-4;
    margin: 0 $space-xs 0 0;
    min-width: 28px;
    vertical-align: top;
  }

  .pager {
    user-select: none;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0;

    li {
      padding: 0 $space-xxs;
      vertical-align: top;
      display: inline-block;
      box-sizing: border-box;
      text-align: center;
      margin: 0;
    }
  }

  .page-tracker {
    color: var(--text-secondary-color);
    white-space: nowrap;
  }

  &.dots {
    span {
      vertical-align: middle;
    }

    .pagination-button {
      border-radius: 100%;
      height: 24px;
      min-height: 24px;
      min-width: 24px;
      padding: 0;
      width: 24px;
      vertical-align: middle;
      outline: solid 1px var(--pager-dot-outline-color);
      outline-offset: -1px;

      span,
      svg {
        display: inline-block;
        font-size: $text-font-size-2;
        line-height: 24px;
      }

      svg {
        margin-top: $space-xxxs;
      }
    }

    .pagination-next,
    .pagination-previous {
      outline-color: transparent;
    }

    .jump {
      height: 24px;
      line-height: 24px;
      vertical-align: middle;

      .editor {
        font-size: $text-font-size-2;
        height: 24px;
        width: 56px;
      }
    }

    .pager {
      vertical-align: middle;

      li {
        vertical-align: middle;
      }
    }

    .total {
      height: 24px;
      line-height: 24px;
      margin: 0 $space-xs 0 0;
      min-width: 24px;
      vertical-align: middle;
    }
  }

  &.gradient {
    .pagination-button {
      &:hover {
        background: var(--pager-button-hover-background-gradient);
      }

      &:active,
      &.active {
        background: var(--pager-button-active-background-gradient);
      }
    }
  }

  &-rtl {
    .pagination-next {
      margin-left: unset;
      margin-right: $space-xxs;
    }

    .pagination-previous {
      margin-left: $space-xxs;
      margin-right: unset;
    }

    .jump {
      margin: 0 $space-xs 0 0;

      .editor {
        margin: 0 $space-xs 0 0;
        padding-left: unset;
        padding-right: $space-xs;
      }
    }

    .total {
      margin: 0 0 0 $space-xs;
    }

    &.dots {
      .total {
        margin: 0 0 0 $space-xs;
      }
    }
  }
}

@media (min-width: $small-screen-size) {
  .pagination {
    .jump {
      display: inline-flex;
    }

    .sizes {
      display: inline-block;
    }

    .total {
      display: inline-block;
    }
  }
}
