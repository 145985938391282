@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.dialog {
  font-family: var(--font-stack-full);
  transform-origin: 50% 50%;
  width: 352px;

  &.small {
    width: 352px;
    @media (max-width: $small-screen-size) {
      width: 80vw;
    }
  }

  &.medium {
    width: 560px;
    @media (max-width: $small-screen-size) {
      width: 90vw;
    }
  }

  .header {
    font-size: $text-font-size-5;
    font-weight: $text-font-weight-semibold;
    margin-bottom: $space-s;
  }

  .body {
    font-size: $text-font-size-3;
    font-weight: $text-font-weight-regular;
    line-height: $text-line-height-3;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: $space-xs;
  }

  &.no-body-padding {
    .header {
      padding-bottom: $space-s;
    }

    .body {
      padding-bottom: 0;
    }
  }
}
