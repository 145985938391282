@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.modal {
  display: flex;
  flex-direction: column;
  font-family: var(--font-stack-full);
  max-height: 70vh;
  overflow: hidden;
  transform-origin: 50% 0;
  width: min(35vw, 100%);

  &.small {
    width: min(35vw, 100%);

    @media (max-width: $small-screen-size) {
      width: 70vw;
    }
  }

  &.medium {
    width: min(48vw, 640px);

    @media (max-width: $small-screen-size) {
      width: 80vw;
    }
  }

  &.large {
    width: min(66vw, 960px);

    @media (max-width: $small-screen-size) {
      width: 90vw;
    }
  }

  &.x-large {
    width: min(85vw, 1500px);

    @media (max-width: $small-screen-size) {
      width: 95vw;
    }
  }

  &.fullscreen {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    border-radius: 0;
  }

  .header {
    @include octuple-h4();
    display: flex;
    flex-direction: row;
    margin-bottom: $space-ml;
    padding: 0;
  }

  .body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    line-height: normal;
  }

  .footer {
    background-color: var(--background-color);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: $space-m;
    gap: $space-xs;
  }

  &.no-body-padding {
    .header {
      padding-bottom: $space-ml;
      margin-bottom: 0;
    }
  }
}
