@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.card {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  color: var(--card-text-color);
  display: block;
  font-family: var(--card-font-family);
  gap: $space-m;
  height: 260px;
  padding: $space-m;
  width: 360px;
  position: relative;

  &-bordered {
    border: 1px solid var(--card-border-color);
  }

  .icon {
    color: inherit;
  }

  .message {
    flex: 1;
    display: flex;
    align-items: center;
    color: inherit;
  }

  .buttons-container {
    padding: $space-m;
  }

  &-large {
    height: 278px;
    padding: $space-ml;
  }

  &-medium {
    height: 260px;
    padding: $space-m;
  }

  &-small {
    height: 260px;
    padding: $space-s;
  }

  &.disabled {
    opacity: $disabled-alpha-value;
    cursor: not-allowed;
  }

  &.drop-shadow {
    box-shadow: $shadow-object-m;
  }

  .header {
    @include octuple-h4();
    display: flex;
    flex-direction: row;
    margin-bottom: $space-ml;
    padding: $disabled-zero;

    .main-header {
      display: flex;
      .icon {
        height: fit-content;
        padding-top: $space-xs;
      }

      .title {
        margin-left: $space-xs;
      }

      &-button {
        margin-right: $space-l;
        padding: $disabled-zero;
        padding-bottom: $space-s;
        background-color: var(--card-background-color);
      }

      &-buttons {
        align-items: flex-end;
        align-self: start;
        height: fit-content;
        justify-content: right;
        white-space: nowrap;

        button {
          margin-left: $space-xs;
        }
      }
    }

    .sub-header {
      opacity: 50%;
      font-style: normal;
      font-weight: $text-font-weight-regular;
      font-size: $text-font-size-3;
      line-height: $text-line-height-3;
      display: flex;

      .separator {
        height: $space-xxs;
        width: $space-xxs;
        margin-right: $space-xs;
        margin-left: $space-xs;
        margin-top: $space-s;
      }
    }

    .button-icon {
      position: absolute;
      right: $disabled-zero;
      background-color: var(--card-background-color);
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    line-height: normal;

    .list {
      margin-bottom: $space-ml;

      &-item {
        display: flex;
      }

      ul {
        flex-wrap: wrap;
        display: -webkit-inline-box;
      }
    }
  }

  .footer {
    padding-top: $space-xs;
    bottom: $space-l;
    position: absolute;
    width: 100%;
    padding-right: 40px;

    .container {
      display: flex;
      justify-content: space-between;

      .content {
        display: flex;
      }
    }
  }

  // Hides the browser default keyboard focus-visible styles.
  // Use the ConfigProvider instead.
  &:focus-visible {
    outline: none;
  }
}

:global(.focus-visible) {
  .card {
    &.focus-visible,
    &:focus-visible {
      box-shadow: var(--focus-visible-box-shadow);

      &.drop-shadow {
        box-shadow: var(--focus-visible-box-shadow), $shadow-object-s;
      }

      &.inset-focus-visible {
        box-shadow: inset var(--focus-visible-box-shadow);

        &.drop-shadow {
          box-shadow: inset var(--focus-visible-box-shadow), $shadow-object-s;
        }
      }
    }
  }
}

@import './Styles/rtl';
