@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
$input-padding-horizontal: $space-xs;
$multi-select-count-offset: 54px;

// Export values for typescript consumption.
:export {
  inputPaddingHorizontal: strip-units($input-padding-horizontal);
  multiSelectCountOffset: strip-units($multi-select-count-offset);
}

.select-wrapper {
  font-family: var(--font-stack-full);
  position: relative;
  width: 100%;

  min-width: 276px;

  // The minimum width required to ensure multi-select pills have enough room for 1 pill + the count
  &-multiple {
    min-width: 324px;
  }

  &-disabled {
    cursor: not-allowed;
  }

  .select-dropdown-overlay {
    padding: 0;

    .select-spinner {
      margin: 10px auto;
      position: relative;
    }
  }

  .select-dropdown-main-wrapper {
    width: 100%;
  }

  .select-input-wrapper {
    border-radius: inherit;
    padding: 0;

    .select-input {
      cursor: pointer;
    }
  }

  .multi-select-pills {
    align-items: center;
    display: flex;
    flex-direction: row;
    left: $space-xs;
    right: $space-xxl;
    pointer-events: none;
    position: absolute;
    top: 6px;
    z-index: 1;
  }

  .multi-select-pill {
    margin-right: $space-xs;
    max-width: 144px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 3px $space-xs;
    pointer-events: all;

    span {
      @include text-overflow;
      max-width: 128px;
    }
  }

  .multi-select-count {
    margin-right: $space-xs;
    padding: 3px $space-xs;
  }

  &.select-large {
    .multi-select-pills {
      top: 7px;

      .multi-select-count,
      .multi-select-pill {
        padding: 3px $space-xs;
      }
    }

    .select-clear-button {
      bottom: $space-xxs;
      height: 36px;
      padding: $space-xxs $space-xs;
      width: $space-xl;
    }

    .select-clear-button-start {
      left: $space-xxs;
      right: unset;
    }

    .select-clear-button-end {
      left: unset;
      right: 44px;
    }
  }

  &.select-medium {
    .multi-select-pills {
      top: 5px;

      .multi-select-count,
      .multi-select-pill {
        padding: 3px $space-xs;
      }
    }

    .select-clear-button {
      bottom: $space-xxs;
      height: 28px;
      padding: $space-xxs 6px;
      width: 28px;
    }

    .select-clear-button-start {
      left: $space-xxs;
      right: unset;
    }

    .select-clear-button-end {
      left: unset;
      right: 36px;
    }
  }

  &.select-small {
    .multi-select-pills {
      top: $space-xxs;

      .multi-select-count,
      .multi-select-pill {
        padding: $space-xxxs $space-s;
      }
    }

    .select-clear-button {
      bottom: $space-xxxs;
      height: $space-l;
      padding: $space-xxxs $space-xxs;
      width: $space-l;
    }

    .select-clear-button-start {
      left: $space-xxxs;
      right: unset;
    }

    .select-clear-button-end {
      left: unset;
      right: 28px;
    }
  }
}

.selected-option {
  background-color: var(--primary-background1-color);
  color: var(--primary-color);
}

.select-menu-empty {
  color: var(--grey-tertiary-color);
  margin: $space-m;
  position: relative;
}

.select-tooltip {
  max-width: unset;
}

@import './Styles/rtl';
