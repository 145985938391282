@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.stack {
  display: flex;
  width: fit-content;

  &.inline {
    display: inline-flex;
  }

  &.full-width {
    width: 100%;
  }

  $gap-space-map: (
    xxxs: $space-xxxs,
    xxs: $space-xxs,
    xs: $space-xs,
    s: $space-s,
    m: $space-m,
    ml: $space-ml,
    l: $space-l,
    xl: $space-xl,
    xxl: $space-xxl,
    xxxl: $space-xxxl,
  );

  @each $gap-name, $size-value in $gap-space-map {
    &.gap-#{$gap-name} {
      gap: $size-value;
    }
  }

  &.vertical {
    flex-direction: column;

    @each $gap-name, $size-value in $gap-space-map {
      &.#{$gap-name} {
        > * + * {
          margin-top: $size-value;
        }
      }
    }
  }

  &.horizontal {
    flex-direction: row;

    @each $gap-name, $size-value in $gap-space-map {
      &.#{$gap-name} {
        > * + * {
          margin-left: $size-value;
        }
      }
    }
  }

  &-rtl {
    &.horizontal {
      @each $gap-name, $size-value in $gap-space-map {
        &.#{$gap-name} {
          > * + * {
            margin-left: unset;
            margin-right: $size-value;
          }
        }
      }
    }
  }
}
