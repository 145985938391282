@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.list-container {
  display: flex;
  font-family: var(--font-stack-full);
  list-style: none;
  margin: 0;
  padding: 0;

  &.vertical {
    flex-direction: column;
  }

  .list-item {
    list-style-type: none;
  }
}
