@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/functions';
@import '../../../styles/themes/definitions';
@import '../../../styles/abstracts/mixins';

:global {
  @import '../../../styles/themes/default-theme';
  @import '../../../styles/base/fonts';
  @import '../../../styles/base/css-reset';
  @import '../../../styles/base/helpers';
}
@import '../DatePicker/Styles/mixins';

.picker {
  border: var(--picker-border);
  border-radius: $picker-border-radius;
  display: inline-flex;
  font-family: var(--font-stack-full);

  &-readonly {
    background: var(--grey-background1-color);
  }

  // Picker default styles are of size medium.
  &-body {
    padding: $space-xs $space-m $space-xs $space-m;
  }

  &-large &-body {
    padding: $space-xs $space-m $space-m $space-m;
  }

  &-medium &-body {
    padding: $space-xs $space-m $space-xs $space-m;
  }

  &-small &-body {
    padding: $space-xxs $space-m $space-xs $space-m;
  }

  &-content {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0;

    tbody {
      display: grid;
      row-gap: 5px;
    }

    thead {
      display: grid;
      row-gap: 0;

      tr {
        th {
          color: var(--picker-header-cell-foreground-color);
          font-weight: $text-font-weight-regular;
          height: $picker-header-cell-height-m;
          width: $picker-header-cell-width-m;
        }
      }
    }
  }

  &-large &-content {
    thead {
      tr {
        th {
          font-size: $picker-font-size-l;
          height: $picker-header-cell-height-l;
          width: $picker-header-cell-width-l;
        }
      }
    }
  }

  &-medium &-content {
    thead {
      tr {
        th {
          font-size: $picker-font-size-m;
          height: $picker-header-cell-height-m;
          width: $picker-header-cell-width-m;
        }
      }
    }
  }

  &-small &-content {
    thead {
      tr {
        th {
          font-size: $picker-font-size-s;
          height: $picker-header-cell-height-s;
          width: $picker-header-cell-width-s;
        }
      }
    }
  }

  &:hover:not(.picker-disabled):not(.picker-underline):not(.picker-borderless) {
    @include hover();
  }

  &-focused:not(.picker-disabled):not(.picker-underline):not(.picker-borderless) {
    @include hover();
  }

  &-borderless {
    border-color: transparent;
    box-shadow: none;
    outline: none;
  }

  &-partial {
    display: inline-block;
    vertical-align: top;
    background: var(--picker-background-color);
    border-radius: $border-radius-m;
    box-shadow: $picker-box-shadow;

    &-focused {
      border-color: var(--focus-visible-shadow-color);
    }
  }

  &-decade-partial,
  &-year-partial,
  &-quarter-partial,
  &-month-partial,
  &-week-partial,
  &-date-partial,
  &-time-partial {
    display: flex;
    flex-direction: column;
  }

  &-header {
    border-bottom: var(--picker-border-width) var(--picker-border-style)
      var(--grey-background2-color);
    display: flex;
    margin: 0 $space-m;
    padding: $space-m 0 $space-s 0;

    > * {
      flex: none;
    }

    &-view {
      flex: auto;
      flex-shrink: 0;
      height: 28px;
      text-align: center;

      &:not(button) {
        line-height: 28px;
      }
    }
  }

  &-large &-header {
    margin: 0 $space-m;
    padding: $space-m 0 $space-s 0;
  }

  &-medium &-header {
    margin: 0 $space-m;
    padding: $space-m 0 $space-s 0;
  }

  &-small &-header {
    margin: 0 $space-m;
    padding: $space-xs 0 $space-xs 0;
  }

  &-cell {
    background: var(--picker-cell-background-color);
    color: var(--picker-cell-foreground-color);
    height: $picker-cell-height-m;
    opacity: $disabled-alpha-value;
    width: $picker-cell-width-m;

    &-disabled > &-inner {
      @include disabled();
    }

    &-inner {
      display: inline-block;
      box-sizing: border-box;
      height: $picker-cell-height-m;
      margin: 0;
      padding: 0;
      font-family: var(--font-stack-full);
      font-size: $picker-font-size-m;
      font-weight: $text-font-weight-regular;
      line-height: $picker-cell-height-m;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      text-align: center;
      width: $picker-cell-width-m;

      &:hover {
        background: var(--picker-cell-background-color-hover);
        color: var(--picker-cell-foreground-color-hover);
        outline: 1px solid var(--picker-cell-border-color-hover);
      }
    }

    &-in-view,
    &-week {
      opacity: 1;
    }

    &-today:not(&-selected) > &-inner {
      background: var(--picker-cell-today-background-color);
      color: var(--picker-cell-today-foreground-color);
      outline: 1px solid var(--picker-cell-today-background-color);

      &:hover {
        outline: 1px solid var(--picker-cell-border-color-hover);
      }
    }

    &-in-range {
      background: var(--picker-cell-background-color-hover);
      box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-hover),
        0 1px 0 0 var(--picker-cell-border-color-hover);
      color: var(--picker-cell-foreground-color-hover);

      .picker-cell-inner {
        outline: none;

        &:hover {
          outline: none;
        }
      }

      &.picker-cell-today {
        .picker-cell-inner {
          background: var(--picker-cell-background-color-hover);
          color: var(--picker-cell-foreground-color-hover);
          outline: none;

          &:hover {
            outline: none;
          }
        }
      }

      &.picker-cell-range-hover-start,
      &.picker-cell-range-hover-end {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        outline: 1px solid var(--picker-cell-border-color-hover);
      }
    }

    &-range-hover {
      background: var(--picker-cell-background-color-hover);
      box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-hover),
        0 1px 0 0 var(--picker-cell-border-color-hover);
      color: var(--picker-cell-foreground-color-hover);
    }

    &-range-hover-start {
      background: var(--picker-cell-range-start-background-color-hover);
      color: var(--picker-cell-foreground-color-hover);
      outline: 1px solid var(--picker-cell-border-color-hover);

      .picker-cell-inner {
        background: var(--picker-cell-range-start-background-color-hover);
        color: var(--picker-cell-foreground-color-hover);
        outline: none;
      }

      &.picker-cell-today {
        .picker-cell-inner {
          background: var(--picker-cell-range-start-background-color-hover);
          color: var(--picker-cell-foreground-color-hover);
          outline: none;

          &:hover {
            outline: none;
          }
        }
      }
    }

    &-range-hover-end {
      background: var(--picker-cell-range-end-background-color-hover);
      color: var(--picker-cell-foreground-color-hover);
      outline: 1px solid var(--picker-cell-border-color-hover);

      .picker-cell-inner {
        background: var(--picker-cell-range-end-background-color-hover);
        color: var(--picker-cell-foreground-color-hover);
        outline: none;
      }

      &.picker-cell-today {
        .picker-cell-inner {
          background: var(--picker-cell-range-end-background-color-hover);
          color: var(--picker-cell-foreground-color-hover);
          outline: none;

          &:hover {
            outline: none;
          }
        }
      }
    }

    &-selected > &-inner,
    &-selected.picker-cell-today > &-inner {
      background: var(--picker-cell-background-color-active);
      color: var(--picker-cell-foreground-color-active);
      outline: 1px solid var(--picker-cell-border-color-active);
    }

    &-range-start {
      background: var(--picker-cell-range-start-background-color-active);
      color: var(--picker-cell-foreground-color-active);
      outline: 1px solid var(--picker-cell-border-color-active);

      .picker-cell-inner {
        background: var(--picker-cell-range-start-background-color-active);
        color: var(--picker-cell-foreground-color-active);
        outline: none;
      }

      &.picker-cell-today {
        .picker-cell-inner {
          background: var(--picker-cell-range-start-background-color-active);
          color: var(--picker-cell-foreground-color-active);
          outline: none;

          &:hover {
            outline: none;
          }
        }
      }

      &.picker-cell-selected {
        .picker-cell-inner {
          outline: none;
        }
      }
    }

    &-range-end {
      background: var(--picker-cell-range-end-background-color-active);
      color: var(--picker-cell-foreground-color-active);
      outline: 1px solid var(--picker-cell-border-color-active);

      .picker-cell-inner {
        background: var(--picker-cell-range-end-background-color-active);
        color: var(--picker-cell-foreground-color-active);
        outline: none;
      }

      &.picker-cell-today {
        .picker-cell-inner {
          background: var(--picker-cell-range-end-background-color-active);
          color: var(--picker-cell-foreground-color-active);
          outline: none;

          &:hover {
            outline: none;
          }
        }
      }

      &.picker-cell-selected {
        .picker-cell-inner {
          outline: none;
        }
      }
    }

    &-range-start:not(&-range-hover),
    &-range-hover-start:not(&-in-range) {
      border-bottom-left-radius: $picker-cell-border-radius-m;
      border-top-left-radius: $picker-cell-border-radius-m;
    }

    &-range-end:not(&-range-hover),
    &-range-hover-end:not(&-in-range) {
      border-bottom-right-radius: $picker-cell-border-radius-m;
      border-top-right-radius: $picker-cell-border-radius-m;
    }
  }

  &-large &-cell {
    height: $picker-cell-height-l;
    width: $picker-cell-width-l;

    &-inner {
      height: $picker-cell-height-l;
      font-size: $picker-font-size-l;
      line-height: $picker-cell-height-l;
      width: $picker-cell-width-l;
    }

    &-range-start:not(&-range-hover),
    &-range-hover-start:not(&-in-range) {
      border-bottom-left-radius: $picker-cell-border-radius-l;
      border-top-left-radius: $picker-cell-border-radius-l;
    }
    &-range-end:not(&-range-hover),
    &-range-hover-end:not(&-in-range) {
      border-bottom-right-radius: $picker-cell-border-radius-l;
      border-top-right-radius: $picker-cell-border-radius-l;
    }
  }

  &-medium &-cell {
    height: $picker-cell-height-m;
    width: $picker-cell-width-m;

    &-inner {
      height: $picker-cell-height-m;
      font-size: $picker-font-size-m;
      line-height: $picker-cell-height-m;
      width: $picker-cell-width-m;
    }

    &-range-start:not(&-range-hover),
    &-range-hover-start:not(&-in-range) {
      border-bottom-left-radius: $picker-cell-border-radius-m;
      border-top-left-radius: $picker-cell-border-radius-m;
    }
    &-range-end:not(&-range-hover),
    &-range-hover-end:not(&-in-range) {
      border-bottom-right-radius: $picker-cell-border-radius-m;
      border-top-right-radius: $picker-cell-border-radius-m;
    }
  }

  &-small &-cell {
    height: $picker-cell-height-s;
    width: $picker-cell-width-s;

    &-inner {
      height: $picker-cell-height-s;
      font-size: $picker-font-size-s;
      line-height: $picker-cell-height-s;
      width: $picker-cell-width-s;
    }

    &-range-start:not(&-range-hover),
    &-range-hover-start:not(&-in-range) {
      border-bottom-left-radius: $picker-cell-border-radius-s;
      border-top-left-radius: $picker-cell-border-radius-s;
    }
    &-range-end:not(&-range-hover),
    &-range-hover-end:not(&-in-range) {
      border-bottom-right-radius: $picker-cell-border-radius-s;
      border-top-right-radius: $picker-cell-border-radius-s;
    }
  }

  &-decade-partial,
  &-year-partial,
  &-month-partial,
  &-time-partial {
    .picker-cell {
      &-inner {
        border-radius: $picker-cell-border-radius-m;
        font-family: var(--font-stack-full);
      }
    }
  }

  &-large &-decade-partial,
  &-large &-year-partial,
  &-large &-month-partial,
  &-large &-time-partial {
    .picker-cell {
      &-inner {
        border-radius: $picker-cell-border-radius-l;
      }
    }
  }

  &-medium &-decade-partial,
  &-medium &-year-partial,
  &-medium &-month-partial,
  &-medium &-time-partial {
    .picker-cell {
      &-inner {
        border-radius: $picker-cell-border-radius-m;
      }
    }
  }

  &-small &-decade-partial,
  &-small &-year-partial,
  &-small &-month-partial,
  &-small &-time-partial {
    .picker-cell {
      &-inner {
        border-radius: $picker-cell-border-radius-s;
      }
    }
  }

  &-large &-decade-partial {
    .picker-cell-inner {
      width: $picker-cell-decade-width-l;
    }
  }

  &-medium &-decade-partial {
    .picker-cell-inner {
      width: $picker-cell-decade-width-m;
    }
  }

  &-small &-decade-partial {
    .picker-cell-inner {
      width: $picker-cell-decade-width-s;
    }
  }

  &-decade-partial,
  &-year-partial,
  &-quarter-partial,
  &-month-partial {
    .picker-body {
      padding: $space-m;
    }

    .picker-header {
      margin: 0 $space-m;
      padding: $space-m 0 $space-s 0;
    }
  }

  &-time-partial {
    .picker-header {
      margin: 0;
      padding: 0 0 $space-s 0;

      &-view {
        color: var(--picker-foreground-color);
        font-size: $picker-font-size-m;
        height: $picker-header-height-m;
        padding: 10px 14px;
      }
    }
  }

  &-large &-time-partial {
    .picker-header {
      &-view {
        font-size: $picker-font-size-l;
        height: $picker-header-height-l;
        padding: 10px 14px;
      }
    }
  }

  &-medium &-time-partial {
    .picker-header {
      padding: 0 0 $space-s 0;

      &-view {
        font-size: $picker-font-size-m;
        height: $picker-header-height-m;
        padding: $space-xxs 14px;
      }
    }
  }

  &-small &-time-partial {
    .picker-header {
      padding: 0 0 $space-xs 0;

      &-view {
        font-size: $picker-font-size-s;
        height: $picker-header-height-s;
        padding: $space-xxxs 14px;
      }
    }
  }

  &-footer {
    font-family: var(--font-stack-full);
    padding: 0 $space-m;
  }

  &-footer-border {
    border-top: var(--picker-border-width) var(--picker-border-style)
      var(--grey-background2-color);
    display: flex;
    height: 100%;
    justify-content: center;
    padding: $space-m 0;
    width: 100%;
  }

  &-large &-footer {
    padding: 0 $space-m;

    .picker-footer-border {
      padding: $space-m 0;
    }
  }

  &-medium &-footer {
    padding: 0 $space-m;

    .picker-footer-border {
      padding: $space-m 0;
    }
  }

  &-small &-footer {
    padding: 0 $space-s;

    .picker-footer-border {
      padding: $space-s 0;
    }
  }

  &-footer-extra {
    font-family: var(--font-stack-full);
    padding: 0 $space-m;
  }

  &-footer-extra-border {
    border-top: var(--picker-border-width) var(--picker-border-style)
      var(--grey-background2-color);
    font-family: var(--font-stack-full);
    padding: $space-m 0;
  }

  &-large &-footer-extra {
    padding: 0 $space-m;

    .picker-footer-extra-border {
      padding: $space-m 0;
    }
  }

  &-medium &-footer-extra {
    padding: 0 $space-m;

    .picker-footer-extra-border {
      padding: $space-m 0;
    }
  }

  &-small &-footer-extra {
    padding: 0 $space-s;

    .picker-footer-extra-border {
      padding: $space-s 0;
    }
  }

  &-ranges {
    display: unset;
    justify-content: unset;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;

    li {
      display: inline-block;
    }
  }

  &-preset {
    margin-right: $space-m;
  }

  &-ok {
    float: right;
  }

  &-year-partial,
  &-month-partial {
    .picker-cell-inner {
      width: $picker-cell-year-month-width-m;
    }
  }

  &-large {
    .picker-year-partial,
    .picker-month-partial {
      .picker-cell-inner {
        width: $picker-cell-year-month-width-l;
      }
    }
  }

  &-medium {
    .picker-year-partial,
    .picker-month-partial {
      .picker-cell-inner {
        width: $picker-cell-year-month-width-m;
      }
    }
  }

  &-small {
    .picker-year-partial,
    .picker-month-partial {
      .picker-cell-inner {
        width: $picker-cell-year-month-width-s;
      }
    }
  }

  &-week-partial {
    &-row {
      &:hover:not(&-selected) {
        .picker-cell {
          background: var(--picker-cell-background-color-hover);
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-hover),
            0 1px 0 0 var(--picker-cell-border-color-hover);
          color: var(--picker-cell-foreground-color-hover);
          outline: none;

          .picker-cell-inner {
            outline: none;
          }

          &-selected {
            .picker-cell-inner {
              outline: none;
            }
          }
        }

        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-m;
          border-top-left-radius: $picker-cell-border-radius-m;
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-hover),
            0 1px 0 0 var(--picker-cell-border-color-hover),
            -1px 0 0 0 var(--picker-cell-border-color-hover);
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-m;
          border-top-right-radius: $picker-cell-border-radius-m;
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-hover),
            0 1px 0 0 var(--picker-cell-border-color-hover),
            1px 0 0 0 var(--picker-cell-border-color-hover);
        }

        &:has(.picker-cell-in-range) {
          .picker-cell {
            background: var(--picker-cell-background-color-active);
          }
        }

        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
          }
        }

        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
          }
        }
      }

      &-selected {
        .picker-cell {
          background: var(--picker-cell-background-color-active);
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
            0 1px 0 0 var(--picker-cell-border-color-active);
          color: var(--picker-cell-foreground-color-active);
          outline: none;

          &-selected {
            outline: none;
            .picker-cell-inner {
              outline: none;
            }
          }
        }

        .picker-cell-today {
          .picker-cell-inner {
            background: var(--picker-cell-background-color-active);
            outline: none;
          }
        }

        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-m;
          border-top-left-radius: $picker-cell-border-radius-m;
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
            0 1px 0 0 var(--picker-cell-border-color-active),
            -1px 0 0 0 var(--picker-cell-border-color-active);
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-m;
          border-top-right-radius: $picker-cell-border-radius-m;
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
            0 1px 0 0 var(--picker-cell-border-color-active),
            1px 0 0 0 var(--picker-cell-border-color-active);
        }

        &:hover {
          .picker-cell {
            background: var(--picker-cell-background-color-active);
            color: var(--picker-cell-foreground-color-active);
            outline: none;

            .picker-cell-inner {
              outline: none;

              &:hover {
                outline: none;
              }
            }

            &-selected {
              .picker-cell-inner {
                outline: none;
              }
            }
          }

          &:has(.picker-cell-in-range) {
            &.picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }

            &.picker-cell:last-of-type {
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-hover-end) {
            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }
          }
        }
      }

      &:has(.picker-cell-range-end),
      &:has(.picker-cell-range-hover-end) {
        .picker-cell {
          background: var(--picker-cell-background-color-active);
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
            0 1px 0 0 var(--picker-cell-border-color-active);
          color: var(--picker-cell-foreground-color-active);
          outline: none;

          &-selected {
            outline: none;
            .picker-cell-inner {
              outline: none;
            }
          }
        }

        .picker-cell-today {
          .picker-cell-inner {
            background: var(--picker-cell-background-color-active);
            outline: none;
          }
        }

        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-m;
          border-top-right-radius: $picker-cell-border-radius-m;
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
            0 1px 0 0 var(--picker-cell-border-color-active),
            1px 0 0 0 var(--picker-cell-border-color-active);
        }

        &:hover {
          .picker-cell {
            background: var(--picker-cell-background-color-active);
            color: var(--picker-cell-foreground-color-active);
            outline: none;

            &-selected {
              .picker-cell-inner {
                outline: none;
              }
            }
          }
        }
      }

      &:has(.picker-cell-range-start),
      &:has(.picker-cell-range-hover-start) {
        .picker-cell {
          background: var(--picker-cell-background-color-active);
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
            0 1px 0 0 var(--picker-cell-border-color-active);
          color: var(--picker-cell-foreground-color-active);
          outline: none;

          &-selected {
            outline: none;
            .picker-cell-inner {
              outline: none;
            }
          }
        }

        .picker-cell-today {
          .picker-cell-inner {
            background: var(--picker-cell-background-color-active);
            outline: none;
          }
        }

        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-m;
          border-top-left-radius: $picker-cell-border-radius-m;
          box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
            0 1px 0 0 var(--picker-cell-border-color-active),
            -1px 0 0 0 var(--picker-cell-border-color-active);
        }

        &:hover {
          .picker-cell {
            background: var(--picker-cell-background-color-active);
            color: var(--picker-cell-foreground-color-active);
            outline: none;

            &-selected {
              .picker-cell-inner {
                outline: none;
              }
            }
          }
        }
      }
    }

    .picker-cell-week {
      color: var(--picker-foreground-color);
      font-weight: $text-font-weight-regular;
      font-size: $picker-font-size-m;
    }

    .picker-cell:hover > .picker-cell-inner,
    .picker-cell-selected > .picker-cell-inner {
      background: transparent;
    }
  }

  &-large &-week-partial {
    &-row {
      &:hover:not(&-selected) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-l;
          border-top-left-radius: $picker-cell-border-radius-l;
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-l;
          border-top-right-radius: $picker-cell-border-radius-l;
        }

        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-l;
            border-top-right-radius: $picker-cell-border-radius-l;
          }
        }

        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-l;
            border-top-left-radius: $picker-cell-border-radius-l;
          }
        }
      }

      &-selected {
        .picker-cell:not(:first-of-type) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        .picker-cell:not(:last-of-type) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:has(.picker-cell-range-end),
      &:has(.picker-cell-range-hover-end) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-l;
          border-top-right-radius: $picker-cell-border-radius-l;
        }
      }

      &:has(.picker-cell-range-start),
      &:has(.picker-cell-range-hover-start) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-l;
          border-top-left-radius: $picker-cell-border-radius-l;
        }
      }
    }

    .picker-cell-week {
      font-size: $picker-font-size-l;
    }
  }

  &-medium &-week-partial {
    &-row {
      &:hover:not(&-selected) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-m;
          border-top-left-radius: $picker-cell-border-radius-m;
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-m;
          border-top-right-radius: $picker-cell-border-radius-m;
        }

        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
          }
        }

        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
          }
        }
      }

      &-selected {
        .picker-cell:not(:first-of-type) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        .picker-cell:not(:last-of-type) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:has(.picker-cell-range-end),
      &:has(.picker-cell-range-hover-end) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-m;
          border-top-right-radius: $picker-cell-border-radius-m;
        }
      }

      &:has(.picker-cell-range-start),
      &:has(.picker-cell-range-hover-start) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-m;
          border-top-left-radius: $picker-cell-border-radius-m;
        }
      }
    }

    .picker-cell-week {
      font-size: $picker-font-size-m;
    }
  }

  &-small &-week-partial {
    &-row {
      &:hover:not(&-selected) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-s;
          border-top-left-radius: $picker-cell-border-radius-s;
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-s;
          border-top-right-radius: $picker-cell-border-radius-s;
        }

        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-s;
            border-top-right-radius: $picker-cell-border-radius-s;
          }
        }

        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-s;
            border-top-left-radius: $picker-cell-border-radius-s;
          }
        }
      }

      &-selected {
        .picker-cell:not(:first-of-type) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        .picker-cell:not(:last-of-type) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:has(.picker-cell-range-end),
      &:has(.picker-cell-range-hover-end) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:last-of-type {
          border-bottom-right-radius: $picker-cell-border-radius-s;
          border-top-right-radius: $picker-cell-border-radius-s;
        }
      }

      &:has(.picker-cell-range-start),
      &:has(.picker-cell-range-hover-start) {
        .picker-cell {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .picker-cell:first-of-type {
          border-bottom-left-radius: $picker-cell-border-radius-s;
          border-top-left-radius: $picker-cell-border-radius-s;
        }
      }
    }

    .picker-cell-week {
      font-size: $picker-font-size-s;
    }
  }

  &-partial-has-range {
    .picker-week-partial {
      &-row {
        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
          }
        }
      }
    }

    &.picker-large .picker-week-partial {
      &-row {
        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-l;
            border-top-right-radius: $picker-cell-border-radius-l;
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-l;
            border-top-left-radius: $picker-cell-border-radius-l;
          }
        }
      }
    }

    &.picker-medium .picker-week-partial {
      &-row {
        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
          }
        }
      }
    }

    &.picker-small .picker-week-partial {
      &-row {
        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-right-radius: $picker-cell-border-radius-s;
            border-top-right-radius: $picker-cell-border-radius-s;
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-s;
            border-top-left-radius: $picker-cell-border-radius-s;
          }
        }
      }
    }
  }

  &-time-partial {
    margin: $space-m 0;
    padding: 0 $space-m;
    width: auto;

    .picker-content {
      display: flex;
      max-height: 368px;
    }

    &-cell {
      &-inner {
        color: var(--picker-cell-foreground-color);
        font-family: var(--font-stack-full);
        font-weight: $text-font-weight-regular;
        height: $picker-cell-height-m;
        line-height: $picker-cell-height-m;
        margin: 0;
        text-align: center;
        width: $picker-cell-width-m;
      }

      &-selected > &-inner {
        background: var(--picker-cell-background-color-active);
        outline: 1px solid var(--picker-cell-border-color-active);
        border-radius: $picker-cell-border-radius-m;
        color: var(--picker-cell-foreground-color-active);
      }

      &-disabled {
        opacity: $disabled-alpha-value;
      }
    }

    &-column {
      @include scroll-bars;

      background: var(--picker-column-background-color);
      flex: none;
      width: 60px;
      margin: 0;
      padding: $space-xs 0;
      overflow-x: hidden;
      overflow-y: hidden;
      font-size: $picker-font-size-m;
      text-align: left;
      list-style: none;

      &-active {
        background: var(--picker-column-background-color-active);
        color: var(--picker-column-foreground-color-active);
      }

      &:hover {
        overflow-y: auto;
      }

      li {
        cursor: pointer;
        margin: 0 $space-xxs $space-xxs;
        padding: 0;

        &:hover:not(.picker-time-partial-cell-selected)
          > .picker-time-partial-cell-inner {
          background: var(--picker-cell-background-color-hover);
          outline: 1px solid var(--picker-cell-border-color-hover);
          border-radius: $picker-cell-border-radius-m;
          color: var(--picker-cell-foreground-color-hover);
        }
      }
    }
  }

  &-large &-time-partial {
    margin: $space-m 0;
    padding: 0 $space-m;

    .picker-content {
      max-height: 368px;
    }

    &-column {
      width: 68px;
      padding: $space-xs 0;
      font-size: $picker-font-size-l;

      li {
        &:hover > .picker-time-partial-cell-inner {
          border-radius: $picker-cell-border-radius-l;
        }
      }
    }

    &-cell {
      &-inner {
        width: $picker-cell-width-l;
        height: $picker-cell-height-l;
        line-height: $picker-cell-height-l;
      }
    }

    &-cell-selected {
      .picker-time-partial-cell-inner {
        border-radius: $picker-cell-border-radius-l;
      }
    }
  }

  &-medium &-time-partial {
    margin: $space-m 0;
    padding: 0 $space-m;

    .picker-content {
      max-height: 308px;
    }

    &-column {
      width: 60px;
      padding: $space-xs 0;
      font-size: $picker-font-size-m;

      li {
        &:hover > .picker-time-partial-cell-inner {
          border-radius: $picker-cell-border-radius-m;
        }
      }
    }

    &-cell {
      &-inner {
        width: $picker-cell-width-m;
        height: $picker-cell-height-m;
        line-height: $picker-cell-height-m;
      }
    }

    &-cell-selected {
      .picker-time-partial-cell-inner {
        border-radius: $picker-cell-border-radius-m;
      }
    }
  }

  &-small &-time-partial {
    margin: $space-xs 0;
    padding: 0 $space-m;

    .picker-content {
      max-height: 248px;
    }

    &-column {
      width: 52px;
      padding: $space-xxs 0;
      font-size: $picker-font-size-s;

      li {
        &:hover > .picker-time-partial-cell-inner {
          border-radius: $picker-cell-border-radius-s;
        }
      }
    }

    &-cell {
      &-inner {
        width: $picker-cell-width-s;
        height: $picker-cell-height-s;
        line-height: $picker-cell-height-s;
      }
    }

    &-cell-selected {
      .picker-time-partial-cell-inner {
        border-radius: $picker-cell-border-radius-s;
      }
    }
  }

  &-datetime-partial {
    display: flex;

    .picker-time-partial {
      border-left: var(--picker-border-width) var(--picker-border-style)
        var(--grey-background2-color);
    }

    .picker-date-partial,
    .picker-time-partial {
      transition: opacity $motion-duration-extra-fast;
    }

    &-active {
      .picker-date-partial,
      .picker-time-partial {
        opacity: 0.3;

        &-active {
          opacity: 1;
        }
      }
    }
  }

  &-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;

    input {
      @include placeholder();

      background-color: transparent;
      background-image: none;
      border: none;
      border-radius: var(--border-radius-m);
      box-sizing: border-box;
      color: var(--picker-input-foreground-color);
      display: inline-block;
      font-family: var(--font-stack-full);
      font-size: $picker-font-size-m;
      height: $picker-input-height-m;
      line-height: $picker-line-height-m;
      margin: $space-xxxs 0;
      min-width: fit-content;
      padding: $space-xs calc(#{$space-xxl} + #{$space-xs}) $space-xs $space-xs;
      position: relative;
      width: 100%;

      &-disabled,
      &[disabled] {
        cursor: not-allowed;
        opacity: $disabled-alpha-value;
      }
    }

    &:hover {
      .picker-clear {
        opacity: 1;
      }
    }

    &-placeholder {
      input {
        color: var(--picker-input-placeholder-color);

        &-disabled,
        &[disabled] {
          @include placeholder(var(--picker-input-foreground-color));
        }
      }
    }
  }

  &-small &-input {
    input {
      margin: $space-xxxs 0;
    }
  }

  &-medium &-input {
    input {
      margin: $space-xxxs 0;
    }
  }

  &-large &-input {
    input {
      margin: $space-xxs 0;
    }
  }

  &-underline {
    .picker-input {
      &:before {
        border-bottom: 1px solid var(--input-border-color);
        left: 0;
        bottom: 0;
        content: '';
        position: absolute;
        right: -$space-xs;
        transition: border-bottom-color $motion-duration-extra-fast
          $motion-easing-easeinout;
        pointer-events: none;
      }

      &:after {
        border-bottom: 1px solid var(--input-border-color-underline-active);
        left: 0;
        bottom: 0;
        content: '';
        position: absolute;
        right: -$space-xs;
        transform: scaleX(0);
        transition: transform $motion-duration-extra-fast
          $motion-easing-easeinout;
        pointer-events: none;
      }

      &:hover {
        &:before {
          border-color: var(--input-border-color-underline-hover);
        }
      }

      &:focus-within {
        &:after {
          transform: scaleX(1) translateX(0px);
        }
      }
    }
  }

  &-disabled {
    @include disabled();

    &.picker-underline {
      .picker-input {
        &:after {
          border-bottom: 1px solid var(--border-color);
          left: 0;
          bottom: 0;
          content: '';
          position: absolute;
          right: -$space-xs;
          transition: border-bottom-color $motion-duration-extra-fast
            $motion-easing-easeinout;
          pointer-events: none;
        }

        &:hover {
          &:before {
            border-color: var(--border-color);
          }
        }
      }
    }

    .picker-input {
      input {
        &-disabled,
        &[disabled] {
          opacity: 1;
        }
      }
    }
  }

  &-status-success &-input {
    input {
      color: var(--input-success-text-color);
    }
  }

  &-status-warning &-input {
    input {
      color: var(--input-warning-text-color);
    }
  }

  &-status-error &-input {
    input {
      color: var(--input-error-text-color);
    }
  }

  &-status-highlight &-input {
    input {
      color: var(--input-highlight-text-color);
    }
  }

  &-suffix {
    display: flex;
    flex: none;
    align-self: center;
    line-height: 1;
    pointer-events: none;

    > * {
      vertical-align: top;

      &:not(:last-child) {
        margin-right: $space-xs;
      }
    }
  }

  &-small &-suffix {
    margin: 0 calc($space-xxs / 2);
  }

  &-medium &-suffix {
    margin: 0 calc($space-m / 2);
  }

  &-large &-suffix {
    margin: 0 calc($space-l / 2);
  }

  &-clear {
    position: absolute;
    right: 0;
    color: var(--picker-input-clear-icon-color);
    line-height: 1;
    background: var(--picker-input-background-color);
    cursor: pointer;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $motion-duration-extra-fast,
      color $motion-duration-extra-fast;

    > * {
      vertical-align: top;
    }

    &-btn:after {
      content: '×';
    }

    &:hover {
      color: var(--picker-input-clear-icon-color-active);
    }
  }

  &-range {
    position: relative;
    display: inline-flex;

    .picker-clear {
      right: $space-xs;
    }

    &:hover {
      .picker-clear {
        opacity: 1;
      }
    }

    &-wrapper {
      display: flex;
    }

    .picker-active-bar {
      bottom: 0;
      height: $space-xxxs;
      background: var(--picker-input-active-bar-color);
      border: 1px solid transparent;
      border-radius: $border-radius-xs;
      opacity: 0;
      transition: all $motion-duration-extra-fast;
      pointer-events: none;
    }

    &.picker-half-disabled,
    &.picker-half-readonly {
      .picker-active-bar {
        transition: none;
      }

      .picker-input {
        input {
          &.input-pill-border-offset {
            margin-left: $space-xs;
          }

          &[readonly] {
            background-color: var(--grey-background1-color);
          }
        }
      }

      .lockIconWrapper {
        display: flex;
      }
    }

    &.picker-focused:not(.picker-readonly) {
      .picker-active-bar {
        opacity: 1;
      }
    }
  }

  &-underline {
    &.picker-range {
      .picker-start-input {
        &:before {
          right: -34px;
        }

        &:after {
          right: -34px;
        }
      }
      .picker-end-input {
        &:before {
          right: -24px;
        }

        &:after {
          right: -24px;
        }
      }
    }

    &.picker-end-readonly {
      .picker-end-input {
        &:before {
          left: -34px;
          right: -54px;
        }

        &:after {
          left: -34px;
          right: -54px;
        }

        &.picker-end-input-small {
          &:before {
            right: -48px;
          }

          &:after {
            right: -48px;
          }
        }

        &.picker-end-input-large {
          &:before {
            right: -64px;
          }

          &:after {
            right: -64px;
          }
        }
      }
    }

    &.picker-start-readonly {
      .picker-start-input {
        &:before {
          right: -72px;
        }

        &:after {
          right: -72px;
        }
      }
    }
  }

  &-large &-range {
    .picker-clear {
      right: $space-s;
      transform: translateY(-$space-s);
    }
  }

  &-partial-container {
    overflow: hidden;
    vertical-align: top;
    background: var(--picker-background-color);
    border-radius: $picker-partial-border-radius;
    box-shadow: $picker-box-shadow;
    transition: margin $motion-duration-extra-fast;

    .picker-partials {
      display: inline-flex;
      flex-wrap: nowrap;
      direction: ltr;
    }

    .picker-partial {
      vertical-align: top;
      background: transparent;
      border-width: 0 0 var(--picker-border-width) 0;
      border-radius: 0;
      box-shadow: none;

      .picker-content,
      table {
        text-align: center;
      }

      &-focused {
        border-color: var(--picker-border-color);
      }
    }
  }

  &-rtl {
    direction: rtl;

    &.picker-underline {
      .picker-input {
        &:before {
          left: -$space-xs;
          right: 0;
        }
        &:after {
          left: -$space-xs;
          right: 0;
        }
      }

      &.picker-range {
        .picker-start-input {
          &:before {
            left: -34px;
            right: 0;
          }

          &:after {
            left: -34px;
            right: 0;
          }
        }
        .picker-end-input {
          &:before {
            left: -24px;
            right: 0;
          }

          &:after {
            left: -24px;
            right: 0;
          }
        }
      }

      &.picker-end-readonly {
        .picker-end-input {
          &:before {
            left: -54px;
            right: -34px;
          }

          &:after {
            left: -54px;
            right: -34px;
          }

          &.picker-end-input-small {
            &:before {
              left: -48px;
            }

            &:after {
              left: -48px;
            }
          }

          &.picker-end-input-large {
            &:before {
              left: -64px;
            }

            &:after {
              left: -64px;
            }
          }
        }
      }

      &.picker-start-readonly {
        .picker-start-input {
          &:before {
            left: -72px;
            right: 0;
          }

          &:after {
            left: -72px;
            right: 0;
          }
        }
      }
    }

    .picker-clear {
      left: 0;
      right: unset;
    }

    &.picker-range {
      &.picker-half-readonly {
        .picker-input {
          input {
            &.input-pill-border-offset {
              margin-left: unset;
              margin-right: $space-xs;
            }
          }
        }
      }

      .picker-clear {
        left: $picker-input-padding-horizontal-l;
        right: unset;
      }
    }
  }

  &-partial-rtl {
    direction: rtl;

    .picker-datetime-partial {
      .picker-time-partial {
        border-left: none;
        border-right: var(--picker-border);
      }
    }

    .picker-ok {
      float: left;
    }

    .picker-cell {
      &-in-range {
        &.picker-cell-range-hover-start,
        &.picker-cell-range-hover-end {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &-range-start:not(.picker-cell-range-hover),
      &-range-hover-start:not(.picker-cell-in-range) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: $picker-cell-border-radius-m;
        border-top-right-radius: $picker-cell-border-radius-m;
      }

      &-range-end:not(.picker-cell-range-hover),
      &-range-hover-end:not(.picker-cell-in-range) {
        border-bottom-left-radius: $picker-cell-border-radius-m;
        border-top-left-radius: $picker-cell-border-radius-m;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.picker-large .picker-cell {
      &-range-start:not(.picker-cell-range-hover),
      &-range-hover-start:not(.picker-cell-in-range) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: $picker-cell-border-radius-l;
        border-top-right-radius: $picker-cell-border-radius-l;
      }
      &-range-end:not(.picker-cell-range-hover),
      &-range-hover-end:not(.picker-cell-in-range) {
        border-bottom-left-radius: $picker-cell-border-radius-l;
        border-top-left-radius: $picker-cell-border-radius-l;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.picker-medium .picker-cell {
      &-range-start:not(.picker-cell-range-hover),
      &-range-hover-start:not(.picker-cell-in-range) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: $picker-cell-border-radius-m;
        border-top-right-radius: $picker-cell-border-radius-m;
      }
      &-range-end:not(.picker-cell-range-hover),
      &-range-hover-end:not(.picker-cell-in-range) {
        border-bottom-left-radius: $picker-cell-border-radius-m;
        border-top-left-radius: $picker-cell-border-radius-m;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.picker-small .picker-cell {
      &-range-start:not(.picker-cell-range-hover),
      &-range-hover-start:not(.picker-cell-in-range) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: $picker-cell-border-radius-s;
        border-top-right-radius: $picker-cell-border-radius-s;
      }
      &-range-end:not(.picker-cell-range-hover),
      &-range-hover-end:not(.picker-cell-in-range) {
        border-bottom-left-radius: $picker-cell-border-radius-s;
        border-top-left-radius: $picker-cell-border-radius-s;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .picker-week-partial {
      &-row {
        &:hover:not(&-selected) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
            box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-hover),
              0 1px 0 0 var(--picker-cell-border-color-hover),
              1px 0 0 0 var(--picker-cell-border-color-hover);
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-hover),
              0 1px 0 0 var(--picker-cell-border-color-hover),
              -1px 0 0 0 var(--picker-cell-border-color-hover);
          }

          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-m;
              border-top-left-radius: $picker-cell-border-radius-m;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-m;
              border-top-right-radius: $picker-cell-border-radius-m;
            }
          }
        }

        &-selected {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
            box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
              0 1px 0 0 var(--picker-cell-border-color-active),
              1px 0 0 0 var(--picker-cell-border-color-active);
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
              0 1px 0 0 var(--picker-cell-border-color-active),
              -1px 0 0 0 var(--picker-cell-border-color-active);
          }

          &:hover {
            &:has(.picker-cell-in-range) {
              &.picker-cell:first-of-type {
                border-bottom-left-radius: $picker-cell-border-radius-m;
                border-top-left-radius: $picker-cell-border-radius-m;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
              }

              &.picker-cell:last-of-type {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border-bottom-right-radius: $picker-cell-border-radius-m;
                border-top-right-radius: $picker-cell-border-radius-m;
              }
            }

            &:has(.picker-cell-range-hover-end) {
              .picker-cell:first-of-type {
                border-bottom-left-radius: $picker-cell-border-radius-m;
                border-top-left-radius: $picker-cell-border-radius-m;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
              }
            }
          }
        }

        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
              0 1px 0 0 var(--picker-cell-border-color-active),
              -1px 0 0 0 var(--picker-cell-border-color-active);
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
            box-shadow: 0 -1px 0 0 var(--picker-cell-border-color-active),
              0 1px 0 0 var(--picker-cell-border-color-active),
              1px 0 0 0 var(--picker-cell-border-color-active);
          }
        }
      }
    }

    &.picker-large .picker-week-partial {
      &-row {
        &:hover:not(&-selected) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-l;
            border-top-right-radius: $picker-cell-border-radius-l;
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-l;
            border-top-left-radius: $picker-cell-border-radius-l;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-l;
              border-top-left-radius: $picker-cell-border-radius-l;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-l;
              border-top-right-radius: $picker-cell-border-radius-l;
            }
          }
        }

        &-selected {
          .picker-cell:not(:last-of-type) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          .picker-cell:not(:first-of-type) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-l;
            border-top-left-radius: $picker-cell-border-radius-l;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-l;
            border-top-right-radius: $picker-cell-border-radius-l;
          }
        }
      }
    }

    &.picker-medium .picker-week-partial {
      &-row {
        &:hover:not(&-selected) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-m;
              border-top-left-radius: $picker-cell-border-radius-m;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-m;
              border-top-right-radius: $picker-cell-border-radius-m;
            }
          }
        }

        &-selected {
          .picker-cell:not(:last-of-type) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          .picker-cell:not(:first-of-type) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-m;
            border-top-left-radius: $picker-cell-border-radius-m;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-m;
            border-top-right-radius: $picker-cell-border-radius-m;
          }
        }
      }
    }

    &.picker-small .picker-week-partial {
      &-row {
        &:hover:not(&-selected) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-s;
            border-top-right-radius: $picker-cell-border-radius-s;
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-s;
            border-top-left-radius: $picker-cell-border-radius-s;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-s;
              border-top-left-radius: $picker-cell-border-radius-s;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-s;
              border-top-right-radius: $picker-cell-border-radius-s;
            }
          }
        }

        &-selected {
          .picker-cell:not(:last-of-type) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          .picker-cell:not(:first-of-type) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:has(.picker-cell-range-end),
        &:has(.picker-cell-range-hover-end) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:last-of-type {
            border-bottom-left-radius: $picker-cell-border-radius-s;
            border-top-left-radius: $picker-cell-border-radius-s;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:has(.picker-cell-range-start),
        &:has(.picker-cell-range-hover-start) {
          .picker-cell {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .picker-cell:first-of-type {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: $picker-cell-border-radius-s;
            border-top-right-radius: $picker-cell-border-radius-s;
          }
        }
      }
    }

    .picker-partial-has-range {
      .picker-week-partial {
        &-row {
          &:has(.picker-cell-range-end),
          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-m;
              border-top-left-radius: $picker-cell-border-radius-m;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-start),
          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-m;
              border-top-right-radius: $picker-cell-border-radius-m;
            }
          }
        }
      }

      &.picker-large .picker-week-partial {
        &-row {
          &:has(.picker-cell-range-end),
          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-l;
              border-top-left-radius: $picker-cell-border-radius-l;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-start),
          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-l;
              border-top-right-radius: $picker-cell-border-radius-l;
            }
          }
        }
      }

      &.picker-medium .picker-week-partial {
        &-row {
          &:has(.picker-cell-range-end),
          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-m;
              border-top-left-radius: $picker-cell-border-radius-m;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-start),
          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-m;
              border-top-right-radius: $picker-cell-border-radius-m;
            }
          }
        }
      }

      &.picker-small .picker-week-partial {
        &-row {
          &:has(.picker-cell-range-end),
          &:has(.picker-cell-range-hover-end) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:last-of-type {
              border-bottom-left-radius: $picker-cell-border-radius-s;
              border-top-left-radius: $picker-cell-border-radius-s;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:has(.picker-cell-range-start),
          &:has(.picker-cell-range-hover-start) {
            .picker-cell {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .picker-cell:first-of-type {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-bottom-right-radius: $picker-cell-border-radius-s;
              border-top-right-radius: $picker-cell-border-radius-s;
            }
          }
        }
      }
    }
  }
}

:global(.focus-visible) {
  .picker {
    &-focused {
      border-color: transparent;
      box-shadow: var(--focus-visible-box-shadow);
    }

    &-partial {
      &-focused {
        border-color: transparent;
        box-shadow: var(--focus-visible-box-shadow), $picker-box-shadow;
      }
    }
  }
}
