.table-wrapper {
  &-rtl {
    direction: rtl;

    .table {
      &-rtl {
        direction: rtl;

        table {
          text-align: right;
        }

        thead {
          > tr {
            > th {
              &[colspan]:not([colspan='1']) {
                text-align: center;
              }

              &:not(:last-child):not(.table-selection-column):not(.table-row-expand-icon-cell):not([colspan])::before {
                right: auto;
                left: 0;
              }

              text-align: right;
            }
          }
        }

        tbody {
          > tr {
            .table-wrapper:only-child {
              margin: -$table-padding-vertical
                ($table-padding-horizontal + ceil(14px * 1.4)) -$table-padding-vertical -$table-padding-horizontal;
            }
          }
        }

        .table-pagination {
          &-left {
            justify-content: flex-end;
          }

          &-right {
            justify-content: flex-start;
          }
        }

        .table-column-sorter {
          margin-right: $space-xxs;
          margin-left: 0;
        }

        .table-filter-column-title {
          padding: $table-padding-vertical $table-padding-horizontal
            $table-padding-vertical 2.3em;
        }

        thead tr th.table-column-has-sorters {
          .table-filter-column-title {
            padding: 0 0 0 2.3em;
          }
        }

        .table-filter-trigger {
          margin: -4px 4px -4px (-$table-padding-horizontal / 2);
        }

        .table-selection {
          text-align: center;
        }

        .table-row-indent {
          float: right;
        }

        .table-row-expand-icon {
          float: right;

          .table-row-indent + & {
            margin-right: 0;
            margin-left: $space-xs;
          }
        }

        tr {
          &:last-of-type {
            td {
              &:first-of-type {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: $table-border-radius;
              }
              &:last-of-type {
                border-bottom-left-radius: $table-border-radius;
                border-bottom-right-radius: 0;
              }
              &:only-of-type {
                border-bottom-left-radius: $table-border-radius;
                border-bottom-right-radius: $table-border-radius;
              }
            }
          }
        }

        &.table-bordered {
          table {
            thead tr th,
            tbody tr td,
            tfoot tr th,
            tfoot tr td {
              border-left: var(--table-border);
              border-right: none;
            }

            tbody tr td,
            tfoot tr th,
            tfoot tr td {
              &:last-of-type {
                border-left: var(--table-border);
                border-right: none;
              }
            }

            thead tr,
            tbody tr,
            tfoot tr {
              .table-cell-fix-right-first:after {
                border-left: var(--table-border-style);
                border-right: none;
              }
            }
          }

          table tbody tr td {
            .table-expanded-row-fixed {
              &:after {
                border-left: var(--table-border);
                border-right: none;
                left: 1px;
                right: unset;
              }
            }
          }

          &.table-scroll-horizontal {
            .table-container .table-body {
              table tbody {
                tr.table-expanded-row,
                tr.table-placeholder {
                  td {
                    border-left: 0;
                    border-right: unset;
                  }
                }
              }
            }
          }
        }

        &.table-cell-bordered {
          table {
            tbody tr td,
            tfoot tr td {
              &:first-of-type {
                border-left: none;
                border-right: none;
              }

              border-left: var(--table-border);

              &:last-of-type {
                border-left: none;
              }
            }

            tbody tr,
            tfoot tr {
              .table-cell-fix-right-first:after {
                border-left: var(--table-border-style);
              }
            }
          }

          table tbody tr td {
            .table-expanded-row-fixed {
              &:after {
                border-left: var(--table-border);
                border-right: unset;
                left: 1px;
                right: unset;
              }
            }
          }
        }

        &.table-header-bordered {
          table {
            thead tr th,
            tfoot tr th {
              &:first-of-type {
                border-left: none;
                border-right: none;
              }

              border-left: var(--table-border);

              &:last-of-type {
                border-left: none;
              }
            }

            thead tr,
            tbody tr,
            tfoot tr {
              .table-cell-fix-right-first:after {
                border-left: var(--table-border-style);
              }
            }
          }

          table tbody tr td {
            .table-expanded-row-fixed {
              &:after {
                border-left: var(--table-border);
                border-right: unset;
                left: 1px;
                right: unset;
              }
            }
          }
        }

        &.table-inner-bordered {
          table {
            thead tr th,
            tbody tr td,
            tfoot tr th,
            tfoot tr td {
              &:first-of-type {
                border-left: none;
                border-right: none;
              }

              border-left: var(--table-border);

              &:last-of-type {
                border-left: none;
              }
            }

            thead tr,
            tbody tr,
            tfoot tr {
              .table-cell-fix-right-first:after {
                border-left: var(--table-border-style);
              }
            }
          }

          table tbody tr td {
            .table-expanded-row-fixed {
              &:after {
                border-left: var(--table-border);
                border-right: unset;
                left: 1px;
                right: unset;
              }
            }
          }
        }
      }
    }
  }
}

.table-filter-dropdown {
  &-rtl {
    .table-filter-dropdown-submenu {
      .table-checkbox-wrapper + span {
        .table-dropdown,
        .table-dropdown-menu-submenu {
          padding-right: 8px;
          padding-left: 0;
        }
      }
    }
  }
}
