@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.theme {
  --primary-background1-color: var(--primary-color-10); // Surface 1
  --primary-background2-color: var(--primary-color-20); // Surface 2
  --primary-background3-color: var(--primary-color-30); // Surface 3
  --primary-tertiary-color: var(--primary-color-60); // Graphics
  --primary-secondary-color: var(--primary-color-70); // Text
  --primary-color: var(--primary-color-80); // Extra high contrast

  --primary-background1-gradient: linear-gradient(
    90deg,
    var(--primary-gradient-start-color-10) 0%,
    var(--primary-gradient-middle-color-10) 49%,
    var(--primary-gradient-end-color-10) 100%
  ); // Surface 1
  --primary-background2-gradient: linear-gradient(
    90deg,
    var(--primary-gradient-start-color-20) 0%,
    var(--primary-gradient-middle-color-20) 49%,
    var(--primary-gradient-end-color-20) 100%
  ); // Surface 2
  --primary-background3-gradient: linear-gradient(
    90deg,
    var(--primary-gradient-start-color-30) 0%,
    var(--primary-gradient-middle-color-30) 49%,
    var(--primary-gradient-end-color-30) 100%
  ); // Surface 3
  --primary-tertiary-gradient: linear-gradient(
    90deg,
    var(--primary-gradient-start-color-60) 0%,
    var(--primary-gradient-middle-color-60) 49%,
    var(--primary-gradient-end-color-60) 100%
  ); // Graphics
  --primary-secondary-gradient: linear-gradient(
    90deg,
    var(--primary-gradient-start-color-70) 0%,
    var(--primary-gradient-middle-color-70) 49%,
    var(--primary-gradient-end-color-70) 100%
  ); // Text
  --primary-gradient: linear-gradient(
    90deg,
    var(--primary-gradient-start-color-80) 0%,
    var(--primary-gradient-middle-color-80) 49%,
    var(--primary-gradient-end-color-80) 100%
  ); // Extra high contrast

  --accent-background1-color: var(--accent-color-10); // Surface 1
  --accent-background2-color: var(--accent-color-20); // Surface 2
  --accent-background3-color: var(--accent-color-30); // Surface 3
  --accent-tertiary-color: var(--accent-color-60); // Graphics
  --accent-secondary-color: var(--accent-color-70); // Text
  --accent-color: var(--accent-color-80); // Extra high contrast

  --accent-background1-gradient: linear-gradient(
    90deg,
    var(--accent-gradient-start-color-10) 0%,
    var(--accent-gradient-middle-color-10) 49%,
    var(--accent-gradient-end-color-10) 100%
  ); // Surface 1
  --accent-background2-gradient: linear-gradient(
    90deg,
    var(--accent-gradient-start-color-20) 0%,
    var(--accent-gradient-middle-color-20) 49%,
    var(--accent-gradient-end-color-20) 100%
  ); // Surface 2
  --accent-background3-gradient: linear-gradient(
    90deg,
    var(--accent-gradient-start-color-30) 0%,
    var(--accent-gradient-middle-color-30) 49%,
    var(--accent-gradient-end-color-30) 100%
  ); // Surface 3
  --accent-tertiary-gradient: linear-gradient(
    90deg,
    var(--accent-gradient-start-color-60) 0%,
    var(--accent-gradient-middle-color-60) 49%,
    var(--accent-gradient-end-color-60) 100%
  ); // Graphics
  --accent-secondary-gradient: linear-gradient(
    90deg,
    var(--accent-gradient-start-color-70) 0%,
    var(--accent-gradient-middle-color-70) 49%,
    var(--accent-gradient-end-color-70) 100%
  ); // Text
  --accent-gradient: linear-gradient(
    90deg,
    var(--accent-gradient-start-color-80) 0%,
    var(--accent-gradient-middle-color-80) 49%,
    var(--accent-gradient-end-color-80) 100%
  ); // Extra high contrast

  --disruptive-background1-color: var(--disruptive-color-10); // Surface 1
  --disruptive-background2-color: var(--disruptive-color-20); // Surface 2
  --disruptive-background3-color: var(--disruptive-color-30); // Surface 3
  --disruptive-tertiary-color: var(--disruptive-color-60); // Graphics
  --disruptive-secondary-color: var(--disruptive-color-70); // Text
  --disruptive-color: var(--disruptive-color-80); // Extra high contrast

  --disruptive-background1-gradient: linear-gradient(
    90deg,
    var(--disruptive-gradient-start-color-10) 0%,
    var(--disruptive-gradient-middle-color-10) 49%,
    var(--disruptive-gradient-end-color-10) 100%
  ); // Surface 1
  --disruptive-background2-gradient: linear-gradient(
    90deg,
    var(--disruptive-gradient-start-color-20) 0%,
    var(--disruptive-gradient-middle-color-20) 49%,
    var(--disruptive-gradient-end-color-20) 100%
  ); // Surface 2
  --disruptive-background3-gradient: linear-gradient(
    90deg,
    var(--disruptive-gradient-start-color-30) 0%,
    var(--disruptive-gradient-middle-color-30) 49%,
    var(--disruptive-gradient-end-color-30) 100%
  ); // Surface 3
  --disruptive-tertiary-gradient: linear-gradient(
    90deg,
    var(--disruptive-gradient-start-color-60) 0%,
    var(--disruptive-gradient-middle-color-60) 49%,
    var(--disruptive-gradient-end-color-60) 100%
  ); // Graphics
  --disruptive-secondary-gradient: linear-gradient(
    90deg,
    var(--disruptive-gradient-start-color-70) 0%,
    var(--disruptive-gradient-middle-color-70) 49%,
    var(--disruptive-gradient-end-color-70) 100%
  ); // Text
  --disruptive-gradient: linear-gradient(
    90deg,
    var(--disruptive-gradient-start-color-80) 0%,
    var(--disruptive-gradient-middle-color-80) 49%,
    var(--disruptive-gradient-end-color-80) 100%
  ); // Extra high contrast

  // ------ Slider theme ------
  --slider-data-active-mark-one-background-color: var(--grey-background3-color);
  --slider-data-active-mark-one-border-color: var(--grey-tertiary-color);
  --slider-data-dot-background-color: var(--grey-background1-color);
  --slider-data-inactive-mark-one-background-color: var(
    --violet-background3-color
  );
  --slider-data-inactive-mark-one-border-color: var(--violetred-tertiary-color);
  --slider-data-inactive-mark-two-background-color: var(
    --blue-background3-color
  );
  --slider-data-inactive-mark-two-border-color: var(
    --blueviolet-tertiary-color
  );
  --slider-data-inactive-mark-three-background-color: var(
    --orange-background3-color
  );
  --slider-data-inactive-mark-three-border-color: var(--orange-tertiary-color);
  --slider-data-rail-background-color: var(--grey-background3-color);
  --slider-data-rail-border-color: var(--grey-tertiary-color);
  --slider-extremity-text-color: var(--grey-tertiary-color);
  --slider-benchmark-background-color: var(--grey-background3-color);
  --slider-benchmark-border-color: var(--grey-tertiary-color);
  --slider-rail-background-color: var(--accent-background1-color);
  --slider-rail-border-color: var(--primary-tertiary-color);
  --slider-thumb-color: var(--accent-background2-color);
  --slider-track-background-color: var(--primary-tertiary-color);
  --slider-track-border-color: var(--primary-tertiary-color);
  --slider-track-success-background-color: var(--green-background3-color);
  --slider-track-success-border-color: var(--green-tertiary-color);
  --slider-track-warning-background-color: var(--orange-background3-color);
  --slider-track-warning-border-color: var(--orange-tertiary-color);
  --slider-track-error-background-color: var(--red-background3-color);
  --slider-track-error-border-color: var(--red-tertiary-color);
  --slider-value-text-color: var(--text-secondary-color);
  // ------ Slider theme ------
}
