@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.trigger-popup {
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: $z-index-top;

  &-hidden {
    display: none;
  }

  // Picker styles
  .picker-range-arrow {
    position: absolute;
    left: $picker-arrow-size;
    z-index: 1;
    width: $picker-arrow-size;
    height: $picker-arrow-size;
    margin-left: $space-xs;
    transition: all 0.3s;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      box-sizing: border-box;
      transform: translate(-50%, -50%);
      content: '';
    }

    &:before {
      width: $picker-arrow-size;
      height: $picker-arrow-size;
      border: calc($picker-arrow-size / 2) solid var(--picker-background-color);
      border-color: var(--picker-background-color)
        var(--picker-background-color) transparent transparent;
    }

    &:after {
      width: $picker-arrow-size - $space-xxxs;
      height: $picker-arrow-size - $space-xxxs;
      border: calc(calc($picker-arrow-size - $space-xxxs) / 2) solid
        var(--picker-background-color);
      border-color: var(--picker-background-color)
        var(--picker-background-color) transparent transparent;
    }
  }

  &-placement-bottomLeft,
  &-placement-bottomRight {
    .picker-range-arrow {
      bottom: unset;
      top: 1px;
      transform: rotate(-45deg);
    }
  }

  &.slide-up-enter.trigger-popup-placement-bottomLeft,
  &.slide-up-enter.trigger-popup-placement-bottomRight {
    animation-name: slideUpIn;
    animation-duration: $motion-duration-extra-fast;
    animation-timing-function: $motion-easing-easeout;
  }

  &.slide-up-leave.trigger-popup-placement-bottomLeft,
  &.slide-up-leave.trigger-popup-placement-bottomRight {
    animation-name: slideUpOut;
    animation-duration: $motion-duration-extra-fast;
    animation-timing-function: $motion-easing-easein;
  }

  &-placement-topLeft,
  &-placement-topRight {
    .picker-range-arrow {
      bottom: $space-xxxs;
      top: unset;
      transform: rotate(135deg);
    }
  }

  &.slide-up-enter.trigger-popup-placement-topLeft,
  &.slide-up-enter.trigger-popup-placement-topRight {
    animation-name: slideDownIn;
    animation-duration: $motion-duration-extra-fast;
    animation-timing-function: $motion-easing-easeout;
  }

  &.slide-up-leave.trigger-popup-placement-topLeft,
  &.slide-up-leave.trigger-popup-placement-topRight {
    animation-name: slideDownOut;
    animation-duration: $motion-duration-extra-fast;
    animation-timing-function: $motion-easing-easein;
  }

  &-range {
    padding: ($picker-arrow-size * 2 / 3) 0;

    &-hidden {
      display: none;
    }
  }

  &-rtl {
    .picker-range-arrow {
      right: $picker-arrow-size;
      left: unset;
      margin-right: 10px;
      margin-left: 0;

      &:before,
      &:after {
        right: 50%;
        left: auto;
        transform: translate(50%, -50%);
      }
    }
  }

  // Assert styles
  @mixin effect() {
    animation-duration: $motion-duration-extra-fast;
    animation-fill-mode: both;
  }

  &-zoom-enter,
  &-zoom-appear {
    opacity: 0;
    @include effect();
    animation-timing-function: $motion-easing-easeout;
    animation-play-state: paused;
  }

  &-zoom-leave {
    @include effect();
    animation-timing-function: $motion-easing-easein;
    animation-play-state: paused;
  }

  &-zoom-enter &-zoom-enter-active,
  &-zoom-appear &-zoom-appear-active {
    animation-name: triggerZoomIn;
    animation-play-state: running;
  }

  &-zoom-leave &-zoom-leave-active {
    animation-name: triggerZoomOut;
    animation-play-state: running;
  }
}

@keyframes slideUpIn {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 0% 0%;
  }
}

@keyframes slideUpOut {
  0% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 0% 0%;
  }
  20% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 0% 0%;
  }
  100% {
    opacity: 0;
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
  }
}

@keyframes slideDownIn {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 100% 100%;
  }
}

@keyframes slideDownOut {
  0% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 100% 100%;
  }
  20% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 100% 100%;
  }
  100% {
    opacity: 0;
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
  }
}

@keyframes triggerZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes triggerZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

@import './Styles/mask';
@import './Styles/mobile';
