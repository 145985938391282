@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.popup {
  border-radius: $border-radius-m;
  padding: 0;
  text-align: left;

  // Popups may be larger than Tooltips
  // Unset max-width and defer width to props if needed.
  &.small {
    max-width: unset;
    width: 140px;
  }

  &.medium {
    max-width: unset;
    width: 240px;
  }

  &.large {
    max-width: unset;
    width: 360px;
  }
}

.popup-rtl {
  &.popup {
    text-align: right;
  }
}
