@import './mixins';
@import './partial';

.picker {
  &-rtl {
    direction: rtl;

    &.picker-large {
      @include picker-padding-rtl(
        $picker-line-height-l,
        $picker-font-size-l,
        $picker-input-padding-horizontal-l
      );
    }

    &.picker-medium {
      @include picker-padding-rtl(
        $picker-line-height-m,
        $picker-font-size-m,
        $picker-input-padding-horizontal-m
      );
    }

    &.picker-small {
      @include picker-padding-rtl(
        $picker-line-height-s,
        $picker-font-size-s,
        $picker-input-padding-horizontal-s
      );
    }

    .picker-input {
      .picker-suffix {
        margin-right: ($space-xs / 2);
        margin-left: 0;
      }

      .picker-clear {
        right: unset;
        left: 0;
      }
    }

    .picker-separator {
      margin-left: $space-xs;
      margin-right: unset;
    }

    &.picker-ranges {
      .picker-dropdown {
        text-align: right;
      }

      .picker-ok {
        .picker-dropdown {
          float: left;
          margin-right: $space-xs;
          margin-left: 0;
        }
      }
    }
  }
}

.picker-partial {
  &-rtl {
    direction: rtl;

    .picker-header {
      &-view {
        button {
          &:not(:first-child) {
            margin-right: $space-xs;
            margin-left: 0;
          }
        }
      }
    }

    @mixin picker-cell-inner() {
      position: relative;
      z-index: 2;
      display: inline-block;
      min-width: $picker-partial-cell-height;
      height: $picker-partial-cell-height;
      line-height: $picker-partial-cell-height;
      border-radius: $picker-border-radius;

      &-in-view.picker-cell-range-start:before {
        left: 0;
        right: 50%;
      }

      &-in-view.picker-cell-range-end:before {
        left: 50%;
        right: 0;
      }

      &-in-view.picker-cell-range-start.picker-cell-range-end:before {
        left: 50%;
        right: 50%;
      }

      .picker-date-partial.picker-cell-in-view.picker-cell-in-range.picker-cell-range-hover-start
        &:after {
        left: -5px - var(--picker-border-width);
        right: 0;
      }

      .picker-date-partial.picker-cell-in-view.picker-cell-in-range.picker-cell-range-hover-end
        &:after {
        left: 0;
        right: -5px - var(--picker-border-width);
      }

      &-range-hover.picker-cell-range-start:after {
        left: 50%;
        right: 0;
      }

      &-range-hover.picker-cell-range-end:after {
        left: 0;
        right: 50%;
      }

      // range start border-radius
      &-in-view.picker-cell-range-start:not(&-range-start-single):not(&-range-end) {
        border-radius: 0 $picker-border-radius $picker-border-radius 0;
      }

      // range end border-radius
      &-in-view.picker-cell-range-end:not(&-range-end-single):not(&-range-start) {
        border-radius: $picker-border-radius 0 0 $picker-border-radius;
      }

      // Edge start
      tr > &-in-view.picker-cell-range-hover:not(&-selected):first-child:after,
      &-in-view.picker-cell-start.picker-cell-range-hover-edge-start.picker-cell-range-hover-edge-start-near-range:after,
      &-in-view.picker-cell-range-hover-edge-start:not(&-range-hover-edge-start-near-range):after,
      &-in-view.picker-cell-range-hover-start:after {
        right: 6px;
        left: 0;
        border-right: var(--picker-border-width) dashed
          $picker-date-hover-range-border-color;
        border-left: none;
        border-radius: 0 $picker-border-radius $picker-border-radius 0;
      }

      // Edge end
      tr > &-in-view.picker-cell-range-hover:not(&-selected):last-child:after,
      &-in-view.picker-cell-end.picker-cell-range-hover-edge-end.picker-cell-range-hover-edge-end-near-range:after,
      &-in-view.picker-cell-range-hover-edge-end:not(&-range-hover-edge-end-near-range):after,
      &-in-view.picker-cell-range-hover-end:after {
        right: 0;
        left: 6px;
        border-right: none;
        border-left: var(--picker-border-width) dashed
          $picker-date-hover-range-border-color;
        border-radius: $picker-border-radius 0 0 $picker-border-radius;
      }

      tr > &-in-view.picker-cell-range-hover-start:last-child:after,
      tr > &-in-view.picker-cell-range-hover-end:first-child:after,
      &-in-view.picker-cell-start.picker-cell-range-hover-edge-start:not(&-range-hover):after,
      &-in-view.picker-cell-start.picker-cell-range-hover-end.picker-cell-range-hover-edge-start:not(&-range-hover):after,
      &-in-view.picker-cell-end.picker-cell-range-hover-start.picker-cell-range-hover-edge-end:not(&-range-hover):after,
      tr
        > &-in-view.picker-cell-start.picker-cell-range-hover.picker-cell-range-hover-edge-start:last-child:after,
      tr
        > &-in-view.picker-cell-end.picker-cell-range-hover.picker-cell-range-hover-edge-end:first-child:after {
        right: 6px;
        left: 6px;
        border-right: var(--picker-border-width) dashed
          $picker-date-hover-range-border-color;
        border-left: var(--picker-border-width) dashed
          $picker-date-hover-range-border-color;
        border-radius: $picker-border-radius;
      }
    }

    .picker-cell {
      @include picker-cell-inner();
    }

    .picker-footer {
      &-extra {
        .picker-dropdown-rtl {
          direction: rtl;
          text-align: right;
        }
      }
    }

    .picker-time-partial {
      direction: ltr;
    }
  }
}
