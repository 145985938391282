@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
$indicator-size: $space-s;

.match-score-container {
  display: flex;
  align-items: center;

  .label {
    color: var(--match-score-text-color);
    font-family: var(--font-stack-full);
    font-size: $text-font-size-3;
    line-height: $indicator-size;
    margin: 0;
    margin-left: $space-xxs;
  }

  .match-score-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--match-score-background-color);
    box-sizing: border-box;
    height: $indicator-size;
    width: $indicator-size;
    border-radius: 50%;
    margin-top: $space-xxxs;
    margin-right: $space-xxs;

    &.full {
      background: var(--match-score-background-color);
    }

    &.half {
      background: var(--match-score-background-color);
      background: linear-gradient(
        to right,
        var(--match-score-background-color) 50%,
        transparent 50%
      );

      &:after {
        content: '';
        height: $indicator-size;
        width: 1px;
        background: var(--match-score-background-color);
      }
    }
  }

  &-rtl {
    .label {
      margin-left: 0;
      margin-right: $space-xxs;
    }

    .match-score-circle {
      margin-left: $space-xxs;
      margin-right: unset;

      &.half {
        background: linear-gradient(
          to left,
          var(--match-score-background-color) 50%,
          transparent 50%
        );
      }
    }
  }
}
