@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/functions';
@import '../../../styles/themes/definitions';
@import '../../../styles/abstracts/mixins';

:global {
  @import '../../../styles/themes/default-theme';
  @import '../../../styles/base/fonts';
  @import '../../../styles/base/css-reset';
  @import '../../../styles/base/helpers';
}
.cropper-modal {
  // Fade-in only as transform doesn't play nicely with canvas.
  // TODO: Add an animationProps prop to BaseDialog then remove this !important.
  animation: fadeIn $motion-duration-extra-fast $motion-easing-easeinout 0s
    forwards !important;

  .cropper-container {
    height: 60vh;
    position: relative;
    width: 100%;
  }

  .cropper-zoom-control,
  .cropper-rotate-control {
    position: relative;
    width: 100%;

    .cropper-icon-button {
      margin-top: calc($space-xxs + 1px);
    }

    .cropper-slider {
      margin-top: $space-xs;
      width: calc(100% - 88px);
    }
  }

  .visuallyhidden {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .cropper-zoom-control {
    margin-top: $space-ml;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
