@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
// Export values for typescript consumption.
:export {
  maxCountFontSize: $text-font-size-3;
}

.wrapper-style {
  align-items: center;
  color: var(--text-inverse-color);
  display: flex;
  font-family: var(--avatar-font-family);
  justify-content: center;

  &.red {
    background: var(--red-secondary-color);
  }

  &.orange {
    background: var(--orange-secondary-color);
  }

  &.red-orange {
    background: var(--redorange-secondary-color);
  }

  &.yellow {
    background: var(--yellow-secondary-color);
  }

  &.yellow-green {
    background: var(--yellowgreen-secondary-color);
  }

  &.green {
    background: var(--green-secondary-color);
  }

  &.blue-green {
    background: var(--bluegreen-secondary-color);
  }

  &.blue {
    background: var(--blue-secondary-color);
  }

  &.blue-violet {
    background: var(--blueviolet-secondary-color);
  }

  &.violet {
    background: var(--violet-secondary-color);
  }

  &.violet-red {
    background: var(--violetred-secondary-color);
  }

  &.grey {
    background: var(--grey-secondary-color);
  }
}

.image-style {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.avatar-status-item {
  display: flex;
  position: absolute;
  border-radius: var(--avatar-status-item-border-radius);

  .clickable {
    cursor: pointer;
  }
}

.avatar-status-item-text {
  font-weight: 600;

  &.text-margin-right {
    margin-right: 2px;
  }

  &.text-margin-left {
    margin-left: 2px;
  }
}

.avatar-status-item-icon {
  margin: 0;
}

.avatar-img-wrapper {
  align-items: center;
  display: flex;
  font-family: var(--avatar-font-family);
  justify-content: center;
}

.avatar-popup {
  z-index: $z-index-500;
}

.avatar-group-max-count,
.avatar-popup,
.avatar-popup-hidden,
.avatar-popup-visible,
.wrapper-style,
.image-style,
.avatar-img-wrapper {
  border-radius: var(--avatar-border-radius);
  position: relative;

  &.round {
    border-radius: var(--avatar-round-border-radius);
  }

  // Hides the browser default keyboard focus-visible styles.
  // Use the ConfigProvider instead.
  &:focus-visible {
    outline: none;
  }
}

:global(.focus-visible) {
  .avatar-group-max-count,
  .avatar-popup,
  .avatar-popup-hidden,
  .avatar-popup-visible,
  .avatar-img-wrapper,
  .image-style,
  .wrapper-style {
    &:focus-visible {
      box-shadow: var(--focus-visible-box-shadow);
    }
  }
}

@import './Styles/group';
@import './Styles/rtl';
