@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.persistent-bar {
  align-items: center;
  background: var(--persistent-bar-background-color);
  border-radius: var(--persistent-bar-border-radius);
  box-shadow: var(--persistent-bar-box-shadow);
  color: var(--persistent-bar-primary-text-color);
  display: flex;
  font-family: var(--font-stack-full);
  gap: $space-m;
  height: auto;
  min-height: $text-line-height-9;
  padding: $space-m;
  width: 100%;

  h4 {
    font-weight: $text-font-weight-semibold;
    margin: $disabled-zero;
  }

  &.bordered {
    border: var(--persistent-bar-border);
  }

  .visually-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &-list {
    display: flex;
    flex-shrink: initial;
    gap: $space-m;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    &-item {
      display: inline-flex;
      list-style-type: none;
    }
  }

  &-menu-item {
    list-style-type: none;
    margin-bottom: $space-s;

    &:first-of-type {
      margin-top: $space-s;
    }
  }

  .content {
    color: var(--persistent-bar-secondary-text-color);
    font-weight: $text-font-weight-regular;
    font-size: $text-font-size-2;
    line-height: $text-line-height-2;
  }

  &.bottom-with-text {
    justify-content: space-between;

    .default-button {
      margin-right: $space-m;
    }
  }

  &.bottom-secondary-buttons {
    justify-content: space-between;
  }

  &.bottom-left-align {
    justify-content: start;
  }

  &.bottom-right-align {
    justify-content: end;
  }

  &.top-button-menu {
    justify-content: center;
  }

  &.top-with-text {
    justify-content: space-between;

    div {
      text-align: center;
    }
  }

  &.top-pagination {
    justify-content: center;
  }

  &-pagination-xsmall {
    button {
      margin: $space-xxs 0;
    }
  }

  .icon-button {
    margin-top: $space-xxs;
  }

  .buttons-container {
    padding: $space-m;
  }

  &-rtl {
    &.bottom-with-text {
      .default-button {
        margin-left: $space-m;
        margin-right: unset;
      }
    }
  }
}
