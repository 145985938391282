@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.navbar-container {
  align-items: center;
  background-color: var(--navbar-background);
  backdrop-filter: var(--navbar-backdrop-filter);
  border-bottom: var(--navbar-border-bottom);
  box-shadow: var(--navbar-box-shadow);
  color: var(--navbar-text-color);
  display: flex;
  flex-direction: row;
  font-family: var(--font-stack-full);
  height: var(--navbar-height);
  justify-content: space-between;
  padding: 0 $space-ml;
  width: 100%;

  .navbar-content {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}
