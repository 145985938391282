.trigger-popup {
  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $all-backdrops;
    height: 100%;
    z-index: 1050;

    &-hidden {
      display: none;
    }
  }

  &-fade-enter,
  &-fade-appear {
    opacity: 0;
    animation-duration: $motion-duration-extra-fast;
    animation-fill-mode: both;
    animation-timing-function: $motion-easing-easeout;
    animation-play-state: paused;
  }

  &-fade-leave {
    animation-duration: $motion-duration-extra-fast;
    animation-fill-mode: both;
    animation-timing-function: $motion-easing-easein;
    animation-play-state: paused;
  }

  &-fade-enter &-fade-enter-active,
  &-fade-appear &-fade-appear-active {
    animation-name: triggerMaskFadeIn;
    animation-play-state: running;
  }

  &-fade-leave &-fade-leave-active {
    animation-name: dialogFadeOut;
    animation-play-state: running;
  }

  @keyframes triggerMaskFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dialogFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
