.input-wrapper {
  &-rtl {
    input {
      padding: $space-xs $space-m $space-xs $space-xl;

      &.with-icon,
      &.with-image-icon {
        padding: $space-xs $space-xxl $space-xs $space-xl;

        &.left-icon {
          padding: $space-xs $space-xxl $space-xs $space-xl;
        }

        &.right-icon {
          padding: $space-xs $space-xl $space-xs $space-xxl;
        }

        &.clear-disabled {
          &.left-icon {
            padding: $space-xs $space-xxl $space-xs $space-xs;
          }

          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xxl;
          }
        }

        &.clear-not-visible {
          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xxl;
          }
        }
      }

      &.with-icon-button {
        &.clear-disabled {
          &.left-icon {
            padding: $space-xs $space-xl $space-xs $space-xs;
          }

          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xl;
          }
        }

        &.clear-not-visible {
          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xl;
          }
        }
      }

      &.with-icon-and-icon-button {
        padding: $space-xs $input-padding-medium-with-icon-and-icon-button
          $space-xs $space-xxl;

        &.left-icon {
          padding: $space-xs $input-padding-medium-with-icon-and-icon-button
            $space-xs $space-xxl;
        }

        &.right-icon {
          padding: $space-xs $space-xxl $space-xs
            $input-padding-medium-with-icon-and-icon-button;
        }

        &.clear-disabled {
          &.left-icon {
            padding: $space-xs $input-padding-medium-with-icon-and-icon-button
              $space-xs $space-xs;
          }

          &.right-icon {
            padding: $space-xs $space-xs $space-xs
              $input-padding-medium-with-icon-and-icon-button;
          }
        }

        &.clear-not-visible {
          &.right-icon {
            padding: $space-xs $space-xs $space-xs
              $input-padding-medium-with-icon-and-icon-button;
          }
        }
      }

      &.pill-shape-with-icon,
      &.pill-shape-with-image-icon {
        padding: $space-xs $space-xxl $space-xs $space-xl;

        &.left-icon {
          padding: $space-xs $space-xxl $space-xs $space-xl;
        }

        &.right-icon {
          padding: $space-xs $space-xl $space-xs $space-xxl;
        }

        &.clear-disabled {
          &.left-icon {
            padding: $space-xs $space-xxl $space-xs $space-xs;
          }

          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xxl;
          }
        }

        &.clear-not-visible {
          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xxl;
          }
        }
      }

      &.pill-shape-with-icon-button {
        &.clear-disabled {
          &.left-icon {
            padding: $space-xs $space-xl $space-xs $space-xs;
          }

          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xl;
          }
        }

        &.clear-not-visible {
          &.right-icon {
            padding: $space-xs $space-xs $space-xs $space-xl;
          }
        }
      }

      &.pill-shape-with-icon-and-icon-button {
        padding: $space-xs $input-padding-medium-with-icon-and-icon-button
          $space-xs $space-xl;

        &.left-icon {
          padding: $space-xs $input-padding-medium-with-icon-and-icon-button
            $space-xs $space-xl;
        }

        &.right-icon {
          padding: $space-xs $space-xl $space-xs
            $input-padding-medium-with-icon-and-icon-button;
        }

        &.clear-disabled {
          &.left-icon {
            padding: $space-xs $input-padding-medium-with-icon-and-icon-button
              $space-xs $space-xs;
          }

          &.right-icon {
            padding: $space-xs $space-xs $space-xs
              $input-padding-medium-with-icon-and-icon-button;
          }
        }

        &.clear-not-visible {
          &.right-icon {
            padding: $space-xs $space-xs $space-xs
              $input-padding-medium-with-icon-and-icon-button;
          }
        }
      }

      &.pill-shape-with-icon-button ~ .icon-button {
        &.left-icon {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 50%;
          border-top-right-radius: 50%;
        }

        &.right-icon {
          border-bottom-left-radius: 50%;
          border-top-left-radius: 50%;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &.pill-shape-with-icon-button ~ .clear-icon-button-rtl {
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      &.pill-shape-with-icon-and-icon-button ~ .icon-button {
        &.left-icon {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: inherit;
          border-top-right-radius: inherit;
        }

        &.right-icon {
          border-bottom-left-radius: inherit;
          border-top-left-radius: inherit;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &.input-large {
        padding: $space-xs $space-m $space-xs $space-xxl;

        &.with-icon,
        &.with-image-icon,
        &.with-icon-button {
          padding: $space-xs $input-padding-large-with-icon $space-xs $space-xxl;

          &.left-icon {
            padding: $space-xs $input-padding-large-with-icon $space-xs
              $space-xxl;
          }

          &.right-icon {
            padding: $space-xs $space-xxl $space-xs
              $input-padding-large-with-icon;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-large-with-icon $space-xs
                $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon;
            }
          }
        }

        &.with-icon-and-icon-button {
          padding: $space-xs $input-padding-large-with-icon-and-icon-button
            $space-xs $space-xxl;

          &.left-icon {
            padding: $space-xs $input-padding-large-with-icon-and-icon-button
              $space-xs 48px;
          }

          &.right-icon {
            padding: $space-xs 48px $space-xs
              $input-padding-large-with-icon-and-icon-button;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-large-with-icon-and-icon-button
                $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon-and-icon-button;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon-and-icon-button;
            }
          }
        }

        &.pill-shape-with-icon,
        &.pill-shape-with-image-icon,
        &.pill-shape-with-icon-button {
          padding: $space-xs $input-padding-large-with-icon $space-xs $space-xxl;

          &.left-icon {
            padding: $space-xs $input-padding-large-with-icon $space-xs
              $space-xxl;
          }

          &.right-icon {
            padding: $space-xs $space-xxl $space-xs
              $input-padding-large-with-icon;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-large-with-icon $space-xs
                $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon;
            }
          }
        }

        &.pill-shape-with-icon-and-icon-button {
          padding: $space-xs $input-padding-large-with-icon-and-icon-button
            $space-xs $space-xxl;

          &.left-icon {
            padding: $space-xs $input-padding-large-with-icon-and-icon-button
              $space-xs $space-xxl;
          }

          &.right-icon {
            padding: $space-xs $space-xxl $space-xs
              $input-padding-large-with-icon-and-icon-button;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-large-with-icon-and-icon-button
                $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon-and-icon-button;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-large-with-icon-and-icon-button;
            }
          }
        }
      }

      &.input-medium {
        padding: $space-xs $space-m $space-xs $space-xl;

        &.with-icon,
        &.with-image-icon {
          padding: $space-xs $space-xxl $space-xs $space-xl;

          &.left-icon {
            padding: $space-xs $space-xxl $space-xs $space-xl;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $space-xxl $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xxl;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xxl;
            }
          }
        }

        &.with-icon-button {
          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $space-xxl $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xxl;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }
        }

        &.with-icon-and-icon-button {
          padding: $space-xs $input-padding-medium-with-icon-and-icon-button
            $space-xs $space-xxl;

          &.left-icon {
            padding: $space-xs $input-padding-medium-with-icon-and-icon-button
              $space-xs $space-xxl;
          }

          &.right-icon {
            padding: $space-xs $space-xxl $space-xs
              $input-padding-medium-with-icon-and-icon-button;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-medium-with-icon-and-icon-button
                $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-medium-with-icon-and-icon-button;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-medium-with-icon-and-icon-button;
            }
          }
        }

        &.pill-shape-with-icon,
        &.pill-shape-with-image-icon {
          padding: $space-xs $space-xxl $space-xs $space-xl;

          &.left-icon {
            padding: $space-xs $space-xxl $space-xs $space-xl;
          }

          &.right-icon {
            padding: $space-xs $space-xl $space-xs $space-xxl;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $space-xxl $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xxl;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xxl;
            }
          }
        }

        &.pill-shape-with-icon-button {
          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $space-xl $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }
        }

        &.pill-shape-with-icon-and-icon-button {
          padding: $space-xs $input-padding-medium-with-icon-and-icon-button
            $space-xs $space-xl;

          &.left-icon {
            padding: $space-xs $input-padding-medium-with-icon-and-icon-button
              $space-xs $space-xl;
          }

          &.right-icon {
            padding: $space-xs $space-xl $space-xs
              $input-padding-medium-with-icon-and-icon-button;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-medium-with-icon-and-icon-button
                $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-medium-with-icon-and-icon-button;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-medium-with-icon-and-icon-button;
            }
          }
        }
      }

      &.input-small {
        padding: $space-xs $space-xs $space-xs $space-l;

        &.with-icon,
        &.with-image-icon,
        &.with-icon-button {
          padding: $space-xs $space-xl $space-xs
            calc(#{$space-l} + #{$space-xxs});

          &.left-icon {
            padding: $space-xs $space-xl $space-xs
              calc(#{$space-l} + #{$space-xxs});
          }

          &.right-icon {
            padding: $space-xs calc(#{$space-l} + #{$space-xxs}) $space-xs
              $space-xl;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $space-xl $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }
        }

        &.with-icon-and-icon-button {
          padding: $space-xs $input-padding-small-with-icon-and-icon-button
            $space-xs calc(#{$space-l} + #{$space-xxs});

          &.left-icon {
            padding: $space-xs $input-padding-small-with-icon-and-icon-button
              $space-xs calc(#{$space-l} + #{$space-xxs});
          }

          &.right-icon {
            padding: $space-xs calc(#{$space-l} + #{$space-xxs}) $space-xs
              $input-padding-small-with-icon-and-icon-button;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-small-with-icon-and-icon-button
                $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-small-with-icon-and-icon-button;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-small-with-icon-and-icon-button;
            }
          }
        }

        &.pill-shape-with-icon,
        &.pill-shape-with-image-icon {
          padding: $space-xs $space-xl $space-xs
            calc(#{$space-l} + #{$space-xxs});

          &.left-icon {
            padding: $space-xs $space-xl $space-xs
              calc(#{$space-l} + #{$space-xxs});
          }

          &.right-icon {
            padding: $space-xs calc(#{$space-l} + #{$space-xxs}) $space-xs
              $space-xl;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $space-xl $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }
        }

        &.pill-shape-with-icon-button {
          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $space-xl $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs $space-xl;
            }
          }
        }

        $input-padding-small-with-icon-and-icon-button-local: 28px;

        &.pill-shape-with-icon-and-icon-button {
          padding: $space-xs $input-padding-small-with-icon-and-icon-button
            $space-xs $input-padding-small-with-icon-and-icon-button-local;

          &.left-icon {
            padding: $space-xs $input-padding-small-with-icon-and-icon-button
              $space-xs $input-padding-small-with-icon-and-icon-button-local;
          }

          &.right-icon {
            padding: $space-xs
              $input-padding-small-with-icon-and-icon-button-local $space-xs
              $input-padding-small-with-icon-and-icon-button;
          }

          &.clear-disabled {
            &.left-icon {
              padding: $space-xs $input-padding-small-with-icon-and-icon-button
                $space-xs $space-xs;
            }

            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-small-with-icon-and-icon-button;
            }
          }

          &.clear-not-visible {
            &.right-icon {
              padding: $space-xs $space-xs $space-xs
                $input-padding-small-with-icon-and-icon-button;
            }
          }
        }
      }
    }

    .icon-wrapper,
    .read-only-icon-wrapper {
      &.left-icon {
        left: unset;
        right: $space-xxs;
      }

      &.right-icon {
        left: $space-xxs;
        right: unset;
      }
    }

    .icon-button {
      &.left-icon {
        left: unset;
        right: $space-xxxs;
      }

      &.right-icon {
        left: $space-xxxs;
        right: unset;
      }
    }

    .clear-icon-button-rtl {
      left: $space-xxxs;
      right: unset;

      &.left-icon {
        left: $space-xxxs;
        right: unset;
      }

      &.right-icon {
        left: unset;
        right: $space-xxxs;
      }
    }

    .icon-wrapper:not(:empty) + .icon-button {
      &.left-icon {
        left: unset;
        right: $space-xl;
      }

      &.right-icon {
        left: $space-xl;
        right: unset;
      }
    }

    .text-area-resize-icon {
      left: $space-xxs;
      right: unset;
    }

    &.pill-shape {
      .text-area-resize-icon {
        left: 10px;
        right: unset;
      }
    }

    &.input-large {
      .icon-wrapper,
      .read-only-icon-wrapper {
        &.left-icon {
          left: unset;
          right: $space-xs;
        }

        &.right-icon {
          left: $space-xs;
          right: unset;
        }
      }

      .icon-button {
        &.left-icon {
          left: unset;
          right: $space-xxs;
        }

        &.right-icon {
          left: $space-xxs;
          right: unset;
        }
      }

      .clear-icon-button-rtl {
        left: $space-xxs;
        right: unset;

        &.left-icon {
          left: $space-xxs;
          right: unset;
        }

        &.right-icon {
          left: unset;
          right: $space-xxs;
        }
      }

      .icon-wrapper:not(:empty) + .icon-button {
        &.left-icon {
          left: unset;
          right: calc($space-xxl + $space-xxs);
        }

        &.right-icon {
          left: calc($space-xxl + $space-xxs);
          right: unset;
        }
      }
    }

    &.input-medium {
      .icon-wrapper,
      .read-only-icon-wrapper {
        &.left-icon {
          left: unset;
          right: $space-xxs;
        }

        &.right-icon {
          left: $space-xxs;
          right: unset;
        }
      }

      .icon-button {
        &.left-icon {
          left: unset;
          right: $space-xxs;
        }

        &.right-icon {
          left: $space-xxs;
          right: unset;
        }
      }

      .clear-icon-button-rtl {
        left: $space-xxs;
        right: unset;

        &.left-icon {
          left: $space-xxs;
          right: unset;
        }

        &.right-icon {
          left: unset;
          right: $space-xxs;
        }
      }

      .icon-wrapper:not(:empty) + .icon-button {
        &.left-icon {
          left: unset;
          right: $space-xl;
        }

        &.right-icon {
          left: $space-xl;
          right: unset;
        }
      }
    }

    &.input-small {
      .icon-wrapper,
      .read-only-icon-wrapper {
        &.left-icon {
          left: unset;
          right: $space-xxs;
        }

        &.right-icon {
          left: $space-xxs;
          right: unset;
        }
      }

      .icon-button {
        &.left-icon {
          left: unset;
          right: $space-xxxs;
        }

        &.right-icon {
          left: $space-xxxs;
          right: unset;
        }
      }

      .clear-icon-button-rtl {
        left: $space-xxxs;
        right: unset;

        &.left-icon {
          left: $space-xxxs;
          right: unset;
        }

        &.right-icon {
          left: unset;
          right: $space-xxxs;
        }
      }

      .icon-wrapper:not(:empty) + .icon-button {
        &.left-icon {
          left: unset;
          right: 28px;
        }

        &.right-icon {
          left: 28px;
          right: unset;
        }
      }
    }

    &.isExpandable {
      .overlay {
        right: 0;
        transform: rotate(180deg);
      }

      .input-group {
        left: unset;
      }

      &.expand-right {
        .input-group,
        .overlay {
          left: 0;
          right: unset;
        }
        .overlay {
          transform: rotate(0deg);
        }
      }
    }
  }
}
