@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
.stepper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .next {
    align-self: flex-start;
    margin-left: $space-m;
  }

  .previous {
    align-self: flex-start;
    margin-right: $space-m;
  }

  .steps-container {
    overflow: hidden;
    overflow-x: auto;
    padding: $space-xxxs;
    position: relative;
    scrollbar-width: none; /* Firefox */
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      visibility: hidden;
    }

    .steps {
      align-items: flex-start;
      color: var(--stepper-line-color);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      list-style: none;
      margin-block-end: 0;
      margin-block-start: 0;
      padding: $space-xs 0;
      width: 100%;

      .separator {
        border: none;
        border-bottom: 1px solid var(--stepper-line-color);
        margin-top: calc(var(--stepper-line-horizontal-offset) + $space-xxxs);
        min-width: 48px;
        width: 100%;

        &.dash,
        &.timeline {
          border-bottom-style: dashed;
        }

        &.dot {
          border-bottom-style: dotted;
        }

        &.solid {
          border-bottom-style: solid;
        }
      }

      .step {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;

        .inner-separator {
          border: none;
          border-bottom: 1px solid var(--stepper-line-color);
          display: none;
          top: var(--stepper-line-horizontal-offset);
          position: absolute;
          width: 100%;
          z-index: 0;

          &.dash,
          &.timeline {
            border-bottom-style: dashed;
          }

          &.dot {
            border-bottom-style: dotted;
          }

          &.solid {
            border-bottom-style: solid;
          }
        }

        .content {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .content-inner {
            color: var(--text-secondary-color);
            font-size: $text-font-size-3;
            font-weight: $text-font-weight-semibold;
            line-height: $text-line-height-3;
            margin: $space-xs 0;
            width: max-content;

            &.large {
              margin: $space-m 0;
            }

            &.medium {
              margin: $space-s 0;
            }

            &.small {
              margin: $space-xs 0;
            }
          }

          .circle {
            align-items: center;
            background: var(--step-default-background-color);
            border-color: var(--step-default-border-color);
            border-style: solid;
            border-radius: var(--border-radius-round);
            border-width: 1px;
            color: var(--step-default-icon-color);
            display: flex;
            flex-direction: row;
            font-size: $text-font-size-4;
            font-weight: $text-font-weight-semibold;
            height: 44px;
            justify-content: center;
            width: 44px;
            z-index: 1;

            .check-icon {
              justify-content: center;
            }

            &.active {
              background: var(--step-default-active-background-color);
              color: var(--step-default-active-icon-color);
            }

            &.complete {
              background: var(--step-default-complete-background-color);
              border-color: var(--step-default-complete-border-color);
              color: var(--step-default-complete-icon-color);
            }

            &.large {
              height: 44px;
              margin-top: -$space-xs;
              font-size: $text-font-size-4;
              width: 44px;
            }

            &.medium {
              height: 36px;
              margin-top: -$space-xxs;
              font-size: $text-font-size-3;
              width: 36px;
            }

            &.small {
              height: 28px;
              font-size: $text-font-size-2;
              width: 28px;
            }
          }

          .circle-button {
            border-radius: var(--border-radius-round);
            display: flex;
            flex-direction: row;
            height: 44px;
            justify-content: center;
            transition: background-color $motion-duration-extra-fast
                $motion-easing-easeinout 0s,
              border-color $motion-duration-extra-fast $motion-easing-easeinout
                0s,
              color $motion-duration-extra-fast $motion-easing-easeinout 0s;
            width: 44px;
            z-index: 1;

            &.circle-primary-button,
            &.circle-secondary-button {
              background: var(--step-default-background-color);
              border: 1px solid var(--step-default-complete-border-color);
              color: var(--step-default-icon-color);
              padding: $button-padding-vertical-large - 1
                $button-padding-horizontal-large - 1;

              &:hover:not(.active) {
                background: var(--step-default-hover-background-color);
                border-color: var(--step-default-hover-border-color);
                color: var(--step-default-hover-icon-color);
              }

              &.complete {
                background: var(--step-default-complete-background-color);
                border-color: var(--step-default-complete-border-color);
                color: var(--step-default-complete-icon-color);
                padding: 9px;
              }

              &.active {
                background: var(--step-default-active-background-color);
                border-color: var(--step-default-active-border-color);
                color: var(--step-default-active-icon-color);

                &:hover {
                  background: var(--step-default-hover-background-color);
                }
              }
            }

            &.circle-secondary-button {
              background: var(--step-default-background-color);
              border: 1px solid var(--step-default-border-color);
              color: var(--step-default-icon-color);

              &:hover:not(.active) {
                background: var(--step-default-hover-background-color);
                border-color: var(--step-default-hover-border-color);
                color: var(--step-default-hover-icon-color);
              }

              &.active {
                background: var(--step-default-active-background-color);
                border-color: var(--step-default-active-border-color);
                color: var(--step-default-active-icon-color);
              }

              &.complete {
                background: var(--step-default-complete-background-color);
                border-color: var(--step-default-complete-border-color);
                color: var(--step-default-complete-icon-color);
              }
            }

            &.large {
              height: 44px;
              width: 44px;

              &.circle-primary-button,
              &.circle-secondary-button {
                margin-top: -$space-xs;
                padding: $button-padding-vertical-large - 1
                  $button-padding-horizontal-large - 1;

                &.complete {
                  padding: 9px;
                }
              }
            }

            &.medium {
              height: 36px;
              width: 36px;

              &.circle-primary-button,
              &.circle-secondary-button {
                margin-top: -$space-xxs;
                padding: $button-padding-vertical-medium - 1
                  $button-padding-horizontal-medium - 1;

                &.complete {
                  padding: 6px;
                }
              }
            }

            &.small {
              height: 28px;
              width: 28px;

              &.circle-primary-button,
              &.circle-secondary-button {
                padding: $button-padding-vertical-small - 1
                  $button-padding-horizontal-small - 1;

                &.complete {
                  padding: 3px;
                }
              }
            }
          }

          .disabled {
            opacity: $disabled-alpha-value;
            cursor: not-allowed;
          }
        }

        &:not(:last-of-type) {
          .inner-separator {
            display: block;
          }
        }
      }
    }
  }

  &.timeline {
    .steps-container {
      .steps {
        .step {
          .content {
            .content-inner {
              &.large {
                margin: $space-m 0;
              }

              &.medium {
                margin: $space-s 0;
              }

              &.small {
                margin: $space-xs 0;
              }
            }

            .circle {
              background: var(--step-timeline-background-color);
              border-color: var(--step-timeline-border-color);
              border-style: solid;
              box-shadow: var(--step-timeline-box-shadow);
              color: var(--step-timeline-icon-color);
              font-size: $text-font-size-2;
              font-weight: $text-font-weight-semibold;
              height: 28px;
              width: 28px;

              &.active {
                background: var(--step-timeline-active-background-color);
                border-color: var(--step-timeline-active-border-color);
                color: var(--step-timeline-active-icon-color);
              }

              &.complete {
                background: var(--step-timeline-complete-background-color);
                border-color: var(--step-timeline-complete-border-color);
                color: var(--step-timeline-complete-icon-color);
              }

              &.large {
                height: 44px;
                margin-top: -$space-xs;
                font-size: $text-font-size-4;
                width: 44px;
              }

              &.medium {
                height: 36px;
                margin-top: -$space-xxs;
                font-size: $text-font-size-3;
                width: 36px;
              }

              &.small {
                height: 28px;
                font-size: $text-font-size-2;
                width: 28px;
              }
            }

            .circle-button {
              height: 28px;
              width: 28px;

              &.circle-default-button {
                background: var(--step-timeline-background-color);
                border: 1px solid var(--step-timeline-border-color);
                box-shadow: var(--step-timeline-box-shadow);
                color: var(--step-timeline-icon-color);
                padding: $button-padding-vertical-small - 1;

                &:hover:not(.active) {
                  background: var(--step-timeline-hover-background-color);
                  border-color: var(--step-timeline-hover-border-color);
                  color: var(--step-timeline-hover-icon-color);
                }

                &.active {
                  background: var(--step-timeline-active-background-color);
                  border-color: var(--step-timeline-active-border-color);
                  color: var(--step-timeline-active-icon-color);
                }
              }

              &.large {
                height: 44px;
                width: 44px;

                &.circle-default-button {
                  margin-top: -$space-xs;
                  padding: $button-padding-vertical-large - 1;

                  &.complete {
                    padding: 9px;
                  }
                }
              }

              &.medium {
                height: 36px;
                width: 36px;

                &.circle-default-button {
                  margin-top: -$space-xxs;
                  padding: $button-padding-vertical-medium - 1;

                  &.complete {
                    padding: 6px;
                  }
                }
              }

              &.small {
                height: 28px;
                width: 28px;

                &.circle-default-button {
                  padding: $button-padding-vertical-small - 1;

                  &.complete {
                    padding: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.medium {
    .steps-container {
      .steps {
        .step {
          .content {
            align-items: flex-start;
            flex-direction: column;

            .content-inner {
              margin: $space-xs 0;
            }
          }

          &:not(:last-of-type) {
            .content {
              margin-right: $space-xxxl;
            }
          }
        }
      }
    }
  }

  &.small {
    .steps-container {
      .steps {
        align-items: center;

        .step {
          .content {
            align-items: center;
            flex-direction: row;

            .content-inner {
              margin: 0 $space-xs;

              &.large,
              &.medium {
                margin: 0 $space-xs;
              }
            }

            .circle {
              &.large {
                margin-top: 0;
              }

              &.medium {
                margin-top: 0;
              }
            }

            .circle-button {
              &.large {
                &.circle-default-button,
                &.circle-primary-button,
                &.circle-secondary-button {
                  margin-top: 0;
                }
              }

              &.medium {
                &.circle-default-button,
                &.circle-primary-button,
                &.circle-secondary-button {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.vertical {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    .next {
      margin-left: 3px;
      margin-top: $space-m;
      transform: rotate(90deg);
    }

    .previous {
      margin-bottom: $space-m;
      margin-left: 3px;
      margin-right: unset;
      margin-top: unset;
      transform: rotate(90deg);
    }

    .steps-container {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      width: unset;

      .steps {
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding: 0 $space-xs;
        width: fit-content;

        .step {
          align-items: flex-start;
          justify-content: flex-start;

          .inner-separator {
            border-bottom: none;
            border-left: var(--stepper-line-width) solid
              var(--stepper-line-color);
            height: 100%;
            left: var(--stepper-line-vertical-offset);
            top: $space-l;
            width: var(--stepper-line-width);

            &.dash,
            &.timeline {
              border-bottom-style: none;
              border-left-style: dashed;
            }

            &.dot {
              border-bottom-style: none;
              border-left-style: dotted;
            }

            &.solid {
              border-bottom-style: none;
              border-left-style: solid;
            }
          }

          .content {
            flex-direction: row;
            margin-right: unset;

            .content-inner {
              align-items: center;
              display: flex;
              justify-content: center;
              margin: auto $space-xs auto $space-l;

              &.large {
                margin: auto $space-xs auto $space-xl;
              }

              &.medium {
                margin: auto $space-xs auto 28px;
              }
            }

            .circle {
              &.large {
                margin-left: -$space-xs;
                margin-top: unset;
              }

              &.medium {
                margin-left: -$space-xxs;
                margin-top: unset;
              }
            }

            .circle-button {
              &.large {
                &.circle-default-button,
                &.circle-primary-button,
                &.circle-secondary-button {
                  margin-left: -$space-xs;
                  margin-top: unset;
                }
              }

              &.medium {
                &.circle-default-button,
                &.circle-primary-button,
                &.circle-secondary-button {
                  margin-left: -$space-xxs;
                  margin-top: unset;
                }
              }
            }
          }

          &:not(:last-of-type) {
            .content {
              margin-bottom: $space-xxxl;
            }
          }
        }
      }
    }

    &.small {
      .steps-container {
        .steps {
          .step {
            .content {
              .content-inner {
                margin: auto $space-xs;

                &.large {
                  margin: auto $space-m;
                }

                &.medium {
                  margin: auto $space-s;
                }

                &.small {
                  margin: auto $space-xs;
                }
              }
            }

            &:not(:last-of-type) {
              .content {
                margin-bottom: $space-xl;
              }
            }
          }
        }
      }
    }

    &.timeline {
      .steps-container {
        .steps {
          .step {
            .inner-separator {
              top: $space-ml;
            }
          }
        }
      }
    }
  }

  &-rtl {
    .next {
      margin-left: unset;
      margin-right: $space-m;
    }

    .previous {
      margin-left: $space-m;
      margin-right: unset;
    }

    &.medium {
      .steps-container {
        .steps {
          .step {
            &:not(:last-of-type) {
              .content {
                margin-left: $space-xxxl;
                margin-right: unset;
              }
            }
          }
        }
      }
    }

    &.vertical {
      .next {
        margin-left: unset;
        margin-right: 3px;
        transform: rotate(-90deg);
      }

      .previous {
        margin-left: unset;
        margin-right: 3px;
        transform: rotate(-90deg);
      }

      .steps-container {
        .steps {
          .step {
            .inner-separator {
              left: unset;
              right: calc(var(--stepper-line-vertical-offset) - $space-xxxs);
            }

            .content {
              margin-left: unset;

              .content-inner {
                margin: auto $space-l auto $space-xs;

                &.large {
                  margin: auto $space-xl auto $space-xs;
                }

                &.medium {
                  margin: auto 28px auto $space-xs;
                }
              }

              .circle {
                &.large {
                  margin-left: unset;
                  margin-right: -$space-xs;
                }

                &.medium {
                  margin-left: unset;
                  margin-right: -$space-xxs;
                }
              }

              .circle-button {
                &.large {
                  &.circle-default-button,
                  &.circle-primary-button,
                  &.circle-secondary-button {
                    margin-left: unset;
                    margin-right: -$space-xs;
                  }
                }

                &.medium {
                  &.circle-default-button,
                  &.circle-primary-button,
                  &.circle-secondary-button {
                    margin-left: unset;
                    margin-right: -$space-xxs;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Default and shared properties
  .steps-container {
    .steps {
      .separator {
        border-bottom-color: var(--stepper-line-color);
        border-left-color: var(--stepper-line-color);
        border-bottom-width: var(--stepper-line-width);
        border-left-width: var(--stepper-line-width);
      }

      .step {
        .inner-separator {
          border-bottom-color: var(--stepper-line-color);
          border-left-color: var(--stepper-line-color);
          border-bottom-width: var(--stepper-line-width);
          border-left-width: var(--stepper-line-width);
        }
      }

      .separator,
      .step .inner-separator,
      .step .content .circle,
      .step .content .circle-button.circle-primary-button,
      .step .content .circle-button.circle-secondary-button {
        &.red {
          --step-default-active-background-color: var(--red-background2-color);
          --step-default-active-border-color: var(--red-tertiary-color);
          --step-default-active-icon-color: var(--red-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--red-tertiary-color);
          --step-default-icon-color: var(--red-secondary-color);
          --step-default-hover-background-color: var(--red-background1-color);
          --step-default-hover-border-color: var(--red-tertiary-color);
          --step-default-hover-icon-color: var(--red-secondary-color);
          --stepper-line-color: var(--red-tertiary-color);
          --step-default-complete-background-color: var(
            --red-background2-color
          );
          --step-default-complete-border-color: var(--red-background2-color);
          --step-default-complete-icon-color: var(--red-secondary-color);
        }

        &.red-orange {
          --step-default-active-background-color: var(
            --redorange-background2-color
          );
          --step-default-active-border-color: var(--redorange-tertiary-color);
          --step-default-active-icon-color: var(--redorange-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--redorange-tertiary-color);
          --step-default-icon-color: var(--redorange-secondary-color);
          --step-default-hover-background-color: var(
            --redorange-background1-color
          );
          --step-default-hover-border-color: var(--redorange-tertiary-color);
          --step-default-hover-icon-color: var(--redorange-secondary-color);
          --stepper-line-color: var(--redorange-tertiary-color);
          --step-default-complete-background-color: var(
            --redorange-background2-color
          );
          --step-default-complete-border-color: var(
            --redorange-background2-color
          );
          --step-default-complete-icon-color: var(--redorange-secondary-color);
        }

        &.orange {
          --step-default-active-background-color: var(
            --orange-background2-color
          );
          --step-default-active-border-color: var(--orange-tertiary-color);
          --step-default-active-icon-color: var(--orange-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--orange-tertiary-color);
          --step-default-icon-color: var(--orange-secondary-color);
          --step-default-hover-background-color: var(
            --orange-background1-color
          );
          --step-default-hover-border-color: var(--orange-tertiary-color);
          --step-default-hover-icon-color: var(--orange-secondary-color);
          --stepper-line-color: var(--orange-tertiary-color);
          --step-default-complete-background-color: var(
            --orange-background2-color
          );
          --step-default-complete-border-color: var(--orange-background2-color);
          --step-default-complete-icon-color: var(--orange-secondary-color);
        }

        &.yellow {
          --step-default-active-background-color: var(
            --yellow-background2-color
          );
          --step-default-active-border-color: var(--yellow-tertiary-color);
          --step-default-active-icon-color: var(--yellow-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--yellow-tertiary-color);
          --step-default-icon-color: var(--yellow-secondary-color);
          --step-default-hover-background-color: var(
            --yellow-background1-color
          );
          --step-default-hover-border-color: var(--yellow-tertiary-color);
          --step-default-hover-icon-color: var(--yellow-secondary-color);
          --stepper-line-color: var(--yellow-tertiary-color);
          --step-default-complete-background-color: var(
            --yellow-background2-color
          );
          --step-default-complete-border-color: var(--yellow-background2-color);
          --step-default-complete-icon-color: var(--yellow-secondary-color);
        }

        &.yellow-green {
          --step-default-active-background-color: var(
            --yellowgreen-background2-color
          );
          --step-default-active-border-color: var(--yellowgreen-tertiary-color);
          --step-default-active-icon-color: var(--yellowgreen-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--yellowgreen-tertiary-color);
          --step-default-icon-color: var(--yellowgreen-secondary-color);
          --step-default-hover-background-color: var(
            --yellowgreen-background1-color
          );
          --step-default-hover-border-color: var(--yellowgreen-tertiary-color);
          --step-default-hover-icon-color: var(--yellowgreen-secondary-color);
          --stepper-line-color: var(--yellowgreen-tertiary-color);
          --step-default-complete-background-color: var(
            --yellowgreen-background2-color
          );
          --step-default-complete-border-color: var(
            --yellowgreen-background2-color
          );
          --step-default-complete-icon-color: var(
            --yellowgreen-secondary-color
          );
        }

        &.green {
          --step-default-active-background-color: var(
            --green-background2-color
          );
          --step-default-active-border-color: var(--green-tertiary-color);
          --step-default-active-icon-color: var(--green-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--green-tertiary-color);
          --step-default-icon-color: var(--green-secondary-color);
          --step-default-hover-background-color: var(--green-background1-color);
          --step-default-hover-border-color: var(--green-tertiary-color);
          --step-default-hover-icon-color: var(--green-secondary-color);
          --stepper-line-color: var(--green-tertiary-color);
          --step-default-complete-background-color: var(
            --green-background2-color
          );
          --step-default-complete-border-color: var(--green-background2-color);
          --step-default-complete-icon-color: var(--green-secondary-color);
        }

        &.blue-green {
          --step-default-active-background-color: var(
            --bluegreen-background2-color
          );
          --step-default-active-border-color: var(--bluegreen-tertiary-color);
          --step-default-active-icon-color: var(--bluegreen-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--bluegreen-tertiary-color);
          --step-default-icon-color: var(--bluegreen-secondary-color);
          --step-default-hover-background-color: var(
            --bluegreen-background1-color
          );
          --step-default-hover-border-color: var(--bluegreen-tertiary-color);
          --step-default-hover-icon-color: var(--bluegreen-secondary-color);
          --stepper-line-color: var(--bluegreen-tertiary-color);
          --step-default-complete-background-color: var(
            --bluegreen-background2-color
          );
          --step-default-complete-border-color: var(
            --bluegreen-background2-color
          );
          --step-default-complete-icon-color: var(--bluegreen-secondary-color);
        }

        &.blue {
          --step-default-active-background-color: var(--blue-background2-color);
          --step-default-active-border-color: var(--blue-tertiary-color);
          --step-default-active-icon-color: var(--blue-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--blue-tertiary-color);
          --step-default-icon-color: var(--blue-secondary-color);
          --step-default-hover-background-color: var(--blue-background1-color);
          --step-default-hover-border-color: var(--blue-tertiary-color);
          --step-default-hover-icon-color: var(--blue-secondary-color);
          --stepper-line-color: var(--blue-tertiary-color);
          --step-default-complete-background-color: var(
            --blue-background2-color
          );
          --step-default-complete-border-color: var(--blue-background2-color);
          --step-default-complete-icon-color: var(--blue-secondary-color);
        }

        &.blue-violet {
          --step-default-active-background-color: var(
            --blueviolet-background2-color
          );
          --step-default-active-border-color: var(--blueviolet-tertiary-color);
          --step-default-active-icon-color: var(--blueviolet-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--blueviolet-tertiary-color);
          --step-default-icon-color: var(--blueviolet-secondary-color);
          --step-default-hover-background-color: var(
            --blueviolet-background1-color
          );
          --step-default-hover-border-color: var(--blueviolet-tertiary-color);
          --step-default-hover-icon-color: var(--blueviolet-secondary-color);
          --stepper-line-color: var(--blueviolet-tertiary-color);
          --step-default-complete-background-color: var(
            --blueviolet-background2-color
          );
          --step-default-complete-border-color: var(
            --blueviolet-background2-color
          );
          --step-default-complete-icon-color: var(--blueviolet-secondary-color);
        }

        &.violet {
          --step-default-active-background-color: var(
            --violet-background2-color
          );
          --step-default-active-border-color: var(--violet-tertiary-color);
          --step-default-active-icon-color: var(--violet-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--violet-tertiary-color);
          --step-default-icon-color: var(--violet-secondary-color);
          --step-default-hover-background-color: var(
            --violet-background1-color
          );
          --step-default-hover-border-color: var(--violet-tertiary-color);
          --step-default-hover-icon-color: var(--violet-secondary-color);
          --stepper-line-color: var(--violet-tertiary-color);
          --step-default-complete-background-color: var(
            --violet-background2-color
          );
          --step-default-complete-border-color: var(--violet-background2-color);
          --step-default-complete-icon-color: var(--violet-secondary-color);
        }

        &.violet-red {
          --step-default-active-background-color: var(
            --violetred-background2-color
          );
          --step-default-active-border-color: var(--violetred-tertiary-color);
          --step-default-active-icon-color: var(--violetred-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--violetred-tertiary-color);
          --step-default-icon-color: var(--violetred-secondary-color);
          --step-default-hover-background-color: var(
            --violetred-background1-color
          );
          --step-default-hover-border-color: var(--violetred-tertiary-color);
          --step-default-hover-icon-color: var(--violetred-secondary-color);
          --stepper-line-color: var(--violetred-tertiary-color);
          --step-default-complete-background-color: var(
            --violetred-background2-color
          );
          --step-default-complete-border-color: var(
            --violetred-background2-color
          );
          --step-default-complete-icon-color: var(--violetred-secondary-color);
        }

        &.grey {
          --step-default-active-background-color: var(--grey-background2-color);
          --step-default-active-border-color: var(--grey-tertiary-color);
          --step-default-active-icon-color: var(--grey-color);
          --step-default-background-color: var(--background-color);
          --step-default-border-color: var(--grey-tertiary-color);
          --step-default-icon-color: var(--grey-secondary-color);
          --step-default-hover-background-color: var(--grey-background1-color);
          --step-default-hover-border-color: var(--grey-tertiary-color);
          --step-default-hover-icon-color: var(--grey-secondary-color);
          --stepper-line-color: var(--grey-tertiary-color);
          --step-default-complete-background-color: var(
            --grey-background2-color
          );
          --step-default-complete-border-color: var(--grey-background2-color);
          --step-default-complete-icon-color: var(--grey-secondary-color);
        }

        &.error {
          --step-default-active-background-color: var(
            --error-active-background-color
          );
          --step-default-active-border-color: var(--error-active-border-color);
          --step-default-active-icon-color: var(--error-active-color);
          --step-default-background-color: var(--error-background-color);
          --step-default-border-color: var(--error-background-color);
          --step-default-icon-color: var(--error-color);
          --step-default-hover-background-color: var(
            --error-hover-background-color
          );
          --step-default-hover-border-color: var(--error-hover-border-color);
          --step-default-hover-icon-color: var(--error-hover-color);
          --stepper-line-color: var(--error-color);
          --step-default-complete-background-color: var(
            --error-background-color
          );
          --step-default-complete-border-color: var(--error-border-color);
          --step-default-complete-icon-color: var(--error-color);
        }

        &.success {
          --step-default-active-background-color: var(
            --success-active-background-color
          );
          --step-default-active-border-color: var(
            --success-active-border-color
          );
          --step-default-active-icon-color: var(--success-active-color);
          --step-default-background-color: var(--success-background-color);
          --step-default-border-color: var(--success-background-color);
          --step-default-icon-color: var(--success-color);
          --step-default-hover-background-color: var(
            --success-hover-background-color
          );
          --step-default-hover-border-color: var(--success-hover-border-color);
          --step-default-hover-icon-color: var(--success-hover-color);
          --stepper-line-color: var(--success-color);
          --step-default-complete-background-color: var(
            --success-background-color
          );
          --step-default-complete-border-color: var(--success-border-color);
          --step-default-complete-icon-color: var(--success-color);
        }

        &.warning {
          --step-default-active-background-color: var(
            --warning-active-background-color
          );
          --step-default-active-border-color: var(
            --warning-active-border-color
          );
          --step-default-active-icon-color: var(--warning-active-color);
          --step-default-background-color: var(--warning-background-color);
          --step-default-border-color: var(--warning-background-color);
          --step-default-icon-color: var(--warning-color);
          --step-default-hover-background-color: var(
            --warning-hover-background-color
          );
          --step-default-hover-border-color: var(--warning-hover-border-color);
          --step-default-hover-icon-color: var(--warning-hover-color);
          --stepper-line-color: var(--warning-color);
          --step-default-complete-background-color: var(
            --warning-background-color
          );
          --step-default-complete-border-color: var(--warning-border-color);
          --step-default-complete-icon-color: var(--warning-color);
        }
      }
    }
  }

  // Timeline theme properties
  &.timeline {
    .steps-container {
      .steps {
        .step .content .circle,
        .step .content .circle-button.circle-default-button {
          &.red {
            --step-timeline-active-background-color: var(
              --red-background2-color
            );
            --step-timeline-active-border-color: var(--red-tertiary-color);
            --step-timeline-active-icon-color: var(--red-color);
            --step-timeline-background-color: var(--red-background1-color);
            --step-timeline-border-color: var(--red-background1-color);
            --step-timeline-icon-color: var(--red-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--red-tertiary-color);
            --step-timeline-hover-icon-color: var(--red-secondary-color);
            --step-timeline-complete-background-color: var(
              --red-background1-color
            );
            --step-timeline-complete-border-color: var(--red-background1-color);
            --step-timeline-complete-icon-color: var(--red-secondary-color);
          }

          &.red-orange {
            --step-timeline-active-background-color: var(
              --redorange-background2-color
            );
            --step-timeline-active-border-color: var(
              --redorange-tertiary-color
            );
            --step-timeline-active-icon-color: var(--redorange-color);
            --step-timeline-background-color: var(
              --redorange-background1-color
            );
            --step-timeline-border-color: var(--redorange-background1-color);
            --step-timeline-icon-color: var(--redorange-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--redorange-tertiary-color);
            --step-timeline-hover-icon-color: var(--redorange-secondary-color);
            --step-timeline-complete-background-color: var(
              --redorange-background1-color
            );
            --step-timeline-complete-border-color: var(
              --redorange-background1-color
            );
            --step-timeline-complete-icon-color: var(
              --redorange-secondary-color
            );
          }

          &.orange {
            --step-timeline-active-background-color: var(
              --orange-background2-color
            );
            --step-timeline-active-border-color: var(--orange-tertiary-color);
            --step-timeline-active-icon-color: var(--orange-color);
            --step-timeline-background-color: var(--orange-background1-color);
            --step-timeline-border-color: var(--orange-background1-color);
            --step-timeline-icon-color: var(--orange-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--orange-tertiary-color);
            --step-timeline-hover-icon-color: var(--orange-secondary-color);
            --step-timeline-complete-background-color: var(
              --orange-background1-color
            );
            --step-timeline-complete-border-color: var(
              --orange-background1-color
            );
            --step-timeline-complete-icon-color: var(--orange-secondary-color);
          }

          &.yellow {
            --step-timeline-active-background-color: var(
              --yellow-background2-color
            );
            --step-timeline-active-border-color: var(--yellow-tertiary-color);
            --step-timeline-active-icon-color: var(--yellow-color);
            --step-timeline-background-color: var(--yellow-background1-color);
            --step-timeline-border-color: var(--yellow-background1-color);
            --step-timeline-icon-color: var(--yellow-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--yellow-tertiary-color);
            --step-timeline-hover-icon-color: var(--yellow-secondary-color);
            --step-timeline-complete-background-color: var(
              --yellow-background1-color
            );
            --step-timeline-complete-border-color: var(
              --yellow-background1-color
            );
            --step-timeline-complete-icon-color: var(--yellow-secondary-color);
          }

          &.yellow-green {
            --step-timeline-active-background-color: var(
              --yellowgreen-background2-color
            );
            --step-timeline-active-border-color: var(
              --yellowgreen-tertiary-color
            );
            --step-timeline-active-icon-color: var(--yellowgreen-color);
            --step-timeline-background-color: var(
              --yellowgreen-background1-color
            );
            --step-timeline-border-color: var(--yellowgreen-background1-color);
            --step-timeline-icon-color: var(--yellowgreen-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(
              --yellowgreen-tertiary-color
            );
            --step-timeline-hover-icon-color: var(
              --yellowgreen-secondary-color
            );
            --step-timeline-complete-background-color: var(
              --yellowgreen-background1-color
            );
            --step-timeline-complete-border-color: var(
              --yellowgreen-background1-color
            );
            --step-timeline-complete-icon-color: var(
              --yellowgreen-secondary-color
            );
          }

          &.green {
            --step-timeline-active-background-color: var(
              --green-background2-color
            );
            --step-timeline-active-border-color: var(--green-tertiary-color);
            --step-timeline-active-icon-color: var(--green-color);
            --step-timeline-background-color: var(--green-background1-color);
            --step-timeline-border-color: var(--green-background1-color);
            --step-timeline-icon-color: var(--green-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--green-tertiary-color);
            --step-timeline-hover-icon-color: var(--green-secondary-color);
            --step-timeline-complete-background-color: var(
              --green-background1-color
            );
            --step-timeline-complete-border-color: var(
              --green-background1-color
            );
            --step-timeline-complete-icon-color: var(--green-secondary-color);
          }

          &.blue-green {
            --step-timeline-active-background-color: var(
              --bluegreen-background2-color
            );
            --step-timeline-active-border-color: var(
              --bluegreen-tertiary-color
            );
            --step-timeline-active-icon-color: var(--bluegreen-color);
            --step-timeline-background-color: var(
              --bluegreen-background1-color
            );
            --step-timeline-border-color: var(--bluegreen-background1-color);
            --step-timeline-icon-color: var(--bluegreen-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--bluegreen-tertiary-color);
            --step-timeline-hover-icon-color: var(--bluegreen-secondary-color);
            --step-timeline-complete-background-color: var(
              --bluegreen-background1-color
            );
            --step-timeline-complete-border-color: var(
              --bluegreen-background1-color
            );
            --step-timeline-complete-icon-color: var(
              --bluegreen-secondary-color
            );
          }

          &.blue {
            --step-timeline-active-background-color: var(
              --blue-background2-color
            );
            --step-timeline-active-border-color: var(--blue-tertiary-color);
            --step-timeline-active-icon-color: var(--blue-color);
            --step-timeline-background-color: var(--blue-background1-color);
            --step-timeline-border-color: var(--blue-background1-color);
            --step-timeline-icon-color: var(--blue-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--blue-tertiary-color);
            --step-timeline-hover-icon-color: var(--blue-secondary-color);
            --step-timeline-complete-background-color: var(
              --blue-background1-color
            );
            --step-timeline-complete-border-color: var(
              --blue-background1-color
            );
            --step-timeline-complete-icon-color: var(--blue-secondary-color);
          }

          &.blue-violet {
            --step-timeline-active-background-color: var(
              --blueviolet-background2-color
            );
            --step-timeline-active-border-color: var(
              --blueviolet-tertiary-color
            );
            --step-timeline-active-icon-color: var(--blueviolet-color);
            --step-timeline-background-color: var(
              --blueviolet-background1-color
            );
            --step-timeline-border-color: var(--blueviolet-background1-color);
            --step-timeline-icon-color: var(--blueviolet-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(
              --blueviolet-tertiary-color
            );
            --step-timeline-hover-icon-color: var(--blueviolet-secondary-color);
            --step-timeline-complete-background-color: var(
              --blueviolet-background1-color
            );
            --step-timeline-complete-border-color: var(
              --blueviolet-background1-color
            );
            --step-timeline-complete-icon-color: var(
              --blueviolet-secondary-color
            );
          }

          &.violet {
            --step-timeline-active-background-color: var(
              --violet-background2-color
            );
            --step-timeline-active-border-color: var(--violet-tertiary-color);
            --step-timeline-active-icon-color: var(--violet-color);
            --step-timeline-background-color: var(--violet-background1-color);
            --step-timeline-border-color: var(--violet-background1-color);
            --step-timeline-icon-color: var(--violet-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--violet-tertiary-color);
            --step-timeline-hover-icon-color: var(--violet-secondary-color);
            --step-timeline-complete-background-color: var(
              --violet-background1-color
            );
            --step-timeline-complete-border-color: var(
              --violet-background1-color
            );
            --step-timeline-complete-icon-color: var(--violet-secondary-color);
          }

          &.violet-red {
            --step-timeline-active-background-color: var(
              --violetred-background2-color
            );
            --step-timeline-active-border-color: var(
              --violetred-tertiary-color
            );
            --step-timeline-active-icon-color: var(--violetred-color);
            --step-timeline-background-color: var(
              --violetred-background1-color
            );
            --step-timeline-border-color: var(--violetred-background1-color);
            --step-timeline-icon-color: var(--violetred-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--violetred-tertiary-color);
            --step-timeline-hover-icon-color: var(--violetred-secondary-color);
            --step-timeline-complete-background-color: var(
              --violetred-background1-color
            );
            --step-timeline-complete-border-color: var(
              --violetred-background1-color
            );
            --step-timeline-complete-icon-color: var(
              --violetred-secondary-color
            );
          }

          &.grey {
            --step-timeline-active-background-color: var(
              --grey-background2-color
            );
            --step-timeline-active-border-color: var(--grey-tertiary-color);
            --step-timeline-active-icon-color: var(--grey-color);
            --step-timeline-background-color: var(--grey-background1-color);
            --step-timeline-border-color: var(--grey-background1-color);
            --step-timeline-icon-color: var(--grey-secondary-color);
            --step-timeline-hover-background-color: var(--background-color);
            --step-timeline-hover-border-color: var(--grey-tertiary-color);
            --step-timeline-hover-icon-color: var(--grey-secondary-color);
            --step-timeline-complete-background-color: var(
              --grey-background1-color
            );
            --step-timeline-complete-border-color: var(
              --grey-background1-color
            );
            --step-timeline-complete-icon-color: var(--grey-secondary-color);
          }

          &.error {
            --step-timeline-active-background-color: var(
              --error-active-background-color
            );
            --step-timeline-active-border-color: var(
              --error-active-border-color
            );
            --step-timeline-active-icon-color: var(--error-active-color);
            --step-timeline-background-color: var(--error-background-color);
            --step-timeline-border-color: var(--error-background-color);
            --step-timeline-icon-color: var(--error-color);
            --step-timeline-hover-background-color: var(
              --error-hover-background-color
            );
            --step-timeline-hover-border-color: var(--error-hover-border-color);
            --step-timeline-hover-icon-color: var(--error-hover-color);
            --step-timeline-complete-background-color: var(
              --error-background-color
            );
            --step-timeline-complete-border-color: var(--error-border-color);
            --step-timeline-complete-icon-color: var(--error-color);
          }

          &.success {
            --step-timeline-active-background-color: var(
              --success-active-background-color
            );
            --step-timeline-active-border-color: var(
              --success-active-border-color
            );
            --step-timeline-active-icon-color: var(--success-active-color);
            --step-timeline-background-color: var(--success-background-color);
            --step-timeline-border-color: var(--success-background-color);
            --step-timeline-icon-color: var(--success-color);
            --step-timeline-hover-background-color: var(
              --success-hover-background-color
            );
            --step-timeline-hover-border-color: var(
              --success-hover-border-color
            );
            --step-timeline-hover-icon-color: var(--success-hover-color);
            --step-timeline-complete-background-color: var(
              --success-background-color
            );
            --step-timeline-complete-border-color: var(--success-border-color);
            --step-timeline-complete-icon-color: var(--success-color);
          }

          &.warning {
            --step-timeline-active-background-color: var(
              --warning-active-background-color
            );
            --step-timeline-active-border-color: var(
              --warning-active-border-color
            );
            --step-timeline-active-icon-color: var(--warning-active-color);
            --step-timeline-background-color: var(--warning-background-color);
            --step-timeline-border-color: var(--warning-background-color);
            --step-timeline-icon-color: var(--warning-color);
            --step-timeline-hover-background-color: var(
              --warning-hover-background-color
            );
            --step-timeline-hover-border-color: var(
              --warning-hover-border-color
            );
            --step-timeline-hover-icon-color: var(--warning-hover-color);
            --step-timeline-complete-background-color: var(
              --warning-background-color
            );
            --step-timeline-complete-border-color: var(--warning-border-color);
            --step-timeline-complete-icon-color: var(--warning-color);
          }
        }
      }
    }
  }

  &.gradient {
    .steps-container {
      .steps {
        .step {
          .content {
            .circle {
              background: var(--step-default-background-gradient);

              &.active {
                background: var(--step-default-active-background-gradient);
              }

              &.complete {
                background: var(--step-default-complete-background-gradient);
              }
            }

            .circle-button {
              &.circle-primary-button,
              &.circle-secondary-button {
                background: var(--step-default-background-gradient);

                &:hover:not(.active) {
                  background: var(--step-default-hover-background-gradient);
                }

                &.complete {
                  background: var(--step-default-complete-background-gradient);
                }

                &.active {
                  background: var(--step-default-active-background-gradient);

                  &:hover {
                    background: var(--step-default-hover-background-gradient);
                  }
                }
              }

              &.circle-secondary-button {
                background: var(--step-default-background-gradient);

                &:hover:not(.active) {
                  background: var(--step-default-hover-background-gradient);
                }

                &.active {
                  background: var(--step-default-active-background-gradient);
                }

                &.complete {
                  background: var(--step-default-complete-background-gradient);
                }
              }
            }
          }
        }
      }
    }

    &.timeline {
      .steps-container {
        .steps {
          .step {
            .content {
              .circle {
                background: var(--step-timeline-background-gradient);

                &.active {
                  background: var(--step-timeline-active-background-gradient);
                }

                &.complete {
                  background: var(--step-timeline-complete-background-gradient);
                }
              }

              .circle-button {
                &.circle-default-button {
                  background: var(--step-timeline-background-gradient);

                  &:hover:not(.active) {
                    background: var(--step-timeline-hover-background-gradient);
                  }

                  &.active {
                    background: var(--step-timeline-active-background-gradient);
                  }
                }
              }
            }
          }
        }
      }
    }

    // Default and shared properties
    .steps-container {
      .steps {
        .separator,
        .step .inner-separator,
        .step .content .circle,
        .step .content .circle-button.circle-primary-button,
        .step .content .circle-button.circle-secondary-button {
          &.red {
            --step-default-active-background-gradient: var(
              --red-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --red-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --red-background2-gradient
            );
          }

          &.red-orange {
            --step-default-active-background-gradient: var(
              --redorange-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --redorange-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --redorange-background2-gradient
            );
          }

          &.orange {
            --step-default-active-background-gradient: var(
              --orange-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --orange-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --orange-background2-gradient
            );
          }

          &.yellow {
            --step-default-active-background-gradient: var(
              --yellow-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --yellow-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --yellow-background2-gradient
            );
          }

          &.yellow-green {
            --step-default-active-background-gradient: var(
              --yellowgreen-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --yellowgreen-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --yellowgreen-background2-gradient
            );
          }

          &.green {
            --step-default-active-background-gradient: var(
              --green-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --green-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --green-background2-gradient
            );
          }

          &.blue-green {
            --step-default-active-background-gradient: var(
              --bluegreen-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --bluegreen-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --bluegreen-background2-gradient
            );
          }

          &.blue {
            --step-default-active-background-gradient: var(
              --blue-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --blue-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --blue-background2-gradient
            );
          }

          &.blue-violet {
            --step-default-active-background-gradient: var(
              --blueviolet-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --blueviolet-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --blueviolet-background2-gradient
            );
          }

          &.violet {
            --step-default-active-background-gradient: var(
              --violet-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --violet-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --violet-background2-gradient
            );
          }

          &.violet-red {
            --step-default-active-background-gradient: var(
              --violetred-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --violetred-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --violetred-background2-gradient
            );
          }

          &.grey {
            --step-default-active-background-gradient: var(
              --grey-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --grey-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --grey-background2-gradient
            );
          }

          &.error {
            --step-default-active-background-gradient: var(
              --red-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --red-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --red-background2-gradient
            );
          }

          &.success {
            --step-default-active-background-gradient: var(
              --green-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --green-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --green-background2-gradient
            );
          }

          &.warning {
            --step-default-active-background-gradient: var(
              --yellow-background2-gradient
            );
            --step-default-background-gradient: var(--background-color);
            --step-default-hover-background-gradient: var(
              --yellow-background1-gradient
            );
            --step-default-complete-background-gradient: var(
              --yellow-background2-gradient
            );
          }
        }
      }
    }

    // Timeline theme properties
    &.timeline {
      .steps-container {
        .steps {
          .step .content .circle,
          .step .content .circle-button.circle-default-button {
            &.red {
              --step-timeline-active-background-gradient: var(
                --red-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --red-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --red-background1-gradient
              );
            }

            &.red-orange {
              --step-timeline-active-background-gradient: var(
                --redorange-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --redorange-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --redorange-background1-gradient
              );
            }

            &.orange {
              --step-timeline-active-background-gradient: var(
                --orange-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --orange-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --orange-background1-gradient
              );
            }

            &.yellow {
              --step-timeline-active-background-gradient: var(
                --yellow-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --yellow-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --yellow-background1-gradient
              );
            }

            &.yellow-green {
              --step-timeline-active-background-gradient: var(
                --yellowgreen-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --yellowgreen-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --yellowgreen-background1-gradient
              );
            }

            &.green {
              --step-timeline-active-background-gradient: var(
                --green-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --green-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --green-background1-gradient
              );
            }

            &.blue-green {
              --step-timeline-active-background-gradient: var(
                --bluegreen-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --bluegreen-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --bluegreen-background1-gradient
              );
            }

            &.blue {
              --step-timeline-active-background-gradient: var(
                --blue-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --blue-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --blue-background1-gradient
              );
            }

            &.blue-violet {
              --step-timeline-active-background-gradient: var(
                --blueviolet-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --blueviolet-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --blueviolet-background1-gradient
              );
            }

            &.violet {
              --step-timeline-active-background-gradient: var(
                --violet-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --violet-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --violet-background1-gradient
              );
            }

            &.violet-red {
              --step-timeline-active-background-gradient: var(
                --violetred-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --violetred-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --violetred-background1-gradient
              );
            }

            &.grey {
              --step-timeline-active-background-gradient: var(
                --grey-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --grey-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --grey-background1-gradient
              );
            }

            &.error {
              --step-timeline-active-background-gradient: var(
                --red-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --red-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --red-background1-gradient
              );
            }

            &.success {
              --step-timeline-active-background-gradient: var(
                --green-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --green-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --green-background1-gradient
              );
            }

            &.warning {
              --step-timeline-active-background-gradient: var(
                --yellow-background2-gradient
              );
              --step-timeline-background-gradient: var(
                --yellow-background1-gradient
              );
              --step-timeline-hover-background-gradient: var(
                --background-color
              );
              --step-timeline-complete-background-gradient: var(
                --yellow-background1-gradient
              );
            }
          }
        }
      }
    }
  }
}
