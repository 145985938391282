@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/functions';
@import '../../styles/themes/definitions';
@import '../../styles/abstracts/mixins';

:global {
  @import '../../styles/themes/default-theme';
  @import '../../styles/base/fonts';
  @import '../../styles/base/css-reset';
  @import '../../styles/base/helpers';
}
$skeleton-pulse-animation: pulse 1.5s ease-in-out 0.5s infinite;
$skeleton-wave-animation: wave 1.6s linear 0.5s infinite;

.skeleton-container {
  position: relative;
  width: fit-content;
  overflow: hidden;
}

.skeleton {
  background: var(--skeleton-background-color);

  &.pulse {
    animation: $skeleton-pulse-animation;
  }

  &.wave {
    overflow: hidden;
    position: relative;
    transform: translateZ(0); // Ensures wave crop in Safari

    &:after {
      animation: $skeleton-wave-animation;
      background: var(--skeleton-wave-background);
      content: '';
      position: absolute;
      transform: translateX(-100%);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  &.children {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.pill {
    border-radius: $border-radius-xl;
  }

  &.button-small {
    border-radius: $border-radius-xl;
    height: 28px;
  }

  &.button-medium {
    border-radius: $border-radius-xl;
    height: 36px;
  }

  &.button-large {
    border-radius: $border-radius-xl;
    height: 44px;
  }

  &.rounded {
    border-radius: $border-radius-m;
  }

  &.circular {
    border-radius: 50%;
  }

  &.full-width {
    width: 100%;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}
